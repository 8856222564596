import { FC } from "react";

export type TypePasswordHint = {
  uppercase: boolean;
  lowercase: boolean;
  number: boolean;
  length: boolean;
  specialChar: boolean;
};

interface PasswordHintProps {
  passwordHints: TypePasswordHint;
  show: boolean;
}

const PasswordHint: FC<PasswordHintProps> = ({ passwordHints, show }) => {
  const successTextClass = "success-icon-text";
  const erroredTextClass = "errored-icon-text";
  const specialCharList = `^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < ' : ; | _ ~ \` + = `; // from aws policy

  if (!show) {
    return null;
  }

  return (
    <div className="pb-2">
      <div className="amplify-flex amplify-alert flex-col items-start gap-0">
        <p className={passwordHints.lowercase ? successTextClass : erroredTextClass}>A lowercase letter</p>
        <p className={passwordHints.uppercase ? successTextClass : erroredTextClass}>A uppercase letter</p>
        <p className={passwordHints.number ? successTextClass : erroredTextClass}>A number</p>
        <p className={passwordHints.length ? successTextClass : erroredTextClass}>Minimum 15 characters</p>
        <p className={passwordHints.specialChar ? successTextClass : erroredTextClass}>A special character</p>

        <div className="text-sm pt-2">
          <p>The following characters count as special characters:</p>
          <code>{specialCharList}</code>
          <p>The non-leading, non-trailing space is also a special character.</p>
        </div>
      </div>
    </div>
  );
};
export default PasswordHint;
