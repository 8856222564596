/*
  useAPI hook is a tuple which provides a predicatble state container for all api calls that are modeled via OpenAPI specification.
  USAGE:
  Using the Open API model you will create an object for the request params

    const requestParams: RegisterDeviceRequest = {
    id: 'test',
    name: '1234'
  }

  This can then be used in a varitey of methods to handle api calls.

  API Call on component mount requires a the url to be set initially:

  const [{ data, status, error, statusCode}, postDevice , refresh ] = useApi('devicePost');

  API Call when called via function requires url to be empty and the second paramter in the tuple to be called:

  postDevice('devicePost');

  Refresh an API Call requires the third paramter in the tuple to be called;

  refresh()

*/

import { useEffect, useReducer, useRef, useState, Dispatch, SetStateAction } from "react";

type ReturnType<C> = [State, Dispatch<SetStateAction<C>>, () => void];

// Interface for Api state
interface State {
  status: "init" | "fetching" | "error" | "fetched";
  data?: any;
  error?: string;
  statusCode?: number;
}

// discriminated union type
type Action =
  | { type: "FETCH_INIT" }
  | { type: "FETCH_SUCCESS"; payload: any; apiStatus?: number }
  | { type: "FETCH_FAILURE"; payload: string; apiStatus?: number };

// Helper to identify empty data objects when passed into Hook
function isEmpty(value) {
  return value == null || value.length === 0 || Object.keys(value).length === 0;
}

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (...args: any) => Promise<infer R>
  ? R
  : never;

export function useApi<C extends () => Promise<any>, T = unknown>(
  apiServiceClient: C,
  options?: any,
  data?: any,
  method?: string,
): ReturnType<keyof AsyncReturnType<C>> {
  type CacheKey = {
    [K in keyof AsyncReturnType<C>]?: T;
  };

  const cache = useRef<CacheKey>({});
  const cancelRequest = useRef<boolean>(false);
  const [url, setUrl] = useState<keyof AsyncReturnType<C>>(undefined);
  const [refreshIndex, setRefreshIndex] = useState<number>(0);
  const initialState: State = {
    status: "init",
    error: undefined,
    data: data,
    statusCode: undefined,
  };

  // Sets Index to know when an api is refreshed
  const refresh = () => {
    setRefreshIndex(refreshIndex + 1);
  };

  // Keep state logic separated
  const fetchReducer = (state: State, action: Action): State => {
    switch (action.type) {
      case "FETCH_INIT":
        return { ...initialState, status: "fetching" };
      case "FETCH_SUCCESS":
        return {
          ...initialState,
          status: "fetched",
          data: action.payload,
          statusCode: action.apiStatus,
        };
      case "FETCH_FAILURE":
        return {
          ...initialState,
          status: "error",
          error: action.payload,
          statusCode: action.apiStatus,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);
  useEffect(() => {
    if (!url) {
      return;
    }

    if (!isEmpty(initialState.data) && refreshIndex === 0)
      dispatch({ type: "FETCH_SUCCESS", payload: initialState.data });
    const fetchData = async () => {
      // const apiService is ts-ignore as the openAPI configuration json mime throws an type error under all circumstances.
      // @ts-ignore

      const apiService = await apiServiceClient();
      dispatch({ type: "FETCH_INIT" });
      if (cache.current[url] && refreshIndex === 0) {
        dispatch({ type: "FETCH_SUCCESS", payload: cache.current[url] });
      } else {
        try {
          const params = options;
          let response: any = {};

          if (method === "GET") {
            response = await apiService[url](...params);
          } else if (method === "PUT" && url === "v1PutUser") {
            response = await apiService[url](params.username, params.updateUser);
          } else if (method === "PUT" && url === "v2PutUser") {
            response = await apiService[url](params.id, params.updateUser, params.headers);
          } else if (url === "v2PutDevice" || url === "v2PostUser" || url === "v2PostDevice") {
            response = await apiService[url](...params);
          } else {
            response = await apiService[url](params);
          }

          cache.current[url] = response.data;
          if (!cancelRequest) return;
          dispatch({
            type: "FETCH_SUCCESS",
            payload: response.data,
            apiStatus: response.status,
          });
        } catch (error) {
          if (!cancelRequest) return;

          dispatch({
            type: "FETCH_FAILURE",
            payload:
              error.response.data.message ??
              error.response.data.Message ??
              error.response.data.Code.toString() ??
              error,
            apiStatus: error.response.status,
          });
        }
      }
    };

    if (url !== "" || refreshIndex > 0) {
      fetchData();
    }
    return () => {
      cancelRequest.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, refreshIndex]);
  return [state, setUrl, refresh];
}

export default useApi;
