import { useContext, useEffect, useState } from "react";
import { useApi } from "./useApi";
import useUserStatus from "./useUserStatus";
import { AppContext } from "../../context/store";
import { branches } from "../mocks";
import { ActionType } from "../../context/reducer";
import { getDeviceManagementApiClient as apiClient } from "../../lib/api-helper/apiHelper";
import { Auth } from "aws-amplify";
import moment from "moment";
import { ROWS_PER_PAGE_V1, ROWS_PER_PAGE_V2 } from "../../constants";
import { useAppVersion } from "./useAppVersion";
import { TableColumn } from "react-data-table-component";
import { Devices } from "../models";
import { useSearchParams } from "react-router-dom";

export const useDevices = () => {
  const { state, dispatch } = useContext(AppContext);
  const { version } = useAppVersion();
  const [offset, setOffset] = useState<string>("");
  const [deviceIds, setSearchDeviceIds] = useState<string>();
  const [searchText, setSearchText] = useState<string>("");
  const [totalRowsDevices, setTotalRowsDevices] = useState<number>();
  const [limit, setLimit] = useState<number>();
  const [sortBy, setSortBy] = useState<string>();
  const [sortDirection, setSortDirection] = useState<string>();
  const [currentState, setCurrentState] = useState<string>("");
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState<number>(Number(searchParams.get("page")) || 1);

  const apiOptions = {
    v1: [limit ?? ROWS_PER_PAGE_V1, offset, deviceIds, searchText],
    v2: [limit ?? ROWS_PER_PAGE_V2, page, searchText, sortBy, sortDirection],
  };

  const user = useUserStatus();
  const [{ data: deviceData }, getDevices, refresh] = useApi(apiClient, apiOptions[`${version}`], currentState, "GET");

  useEffect(() => {
    if (!user) return;
    async function fetchCurrentUserRole() {
      const idToken = (await Auth.currentSession()).getIdToken();
      dispatch({
        type: ActionType.SET_USER_ROLE,
        payload: idToken.payload["custom:role"],
      });
    }
    fetchCurrentUserRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (version === "v2" && searchParams) {
      if (searchParams.get("limit")) {
        setLimit(Number(searchParams.get("limit")));
        handleRowsPerPageChange(Number(searchParams.get("limit")), page);
      }
      if (searchParams.get("page")) {
        handlePageChange(Number(searchParams.get("page")));
      }
      if (searchParams.get("q")) {
        setSearchText(String(searchParams.get("q")));
      }
      if (searchParams.get("sortBy")) {
        setSortBy(String(searchParams.get("sortBy")));
      }
      if (searchParams.get("sortDirection")) {
        setSortDirection(String(searchParams.get("sortDirection")));
      }
    }
    if (version === "v1" && searchParams) {
      if (searchParams.get("limit")) {
        setLimit(Number(searchParams.get("limit")));
        handleRowsPerPageChange(Number(searchParams.get("limit")), page);
      }
      if (searchParams.get("page")) {
        handlePageChange(Number(searchParams.get("offset")));
      }
      if (searchParams.get("q")) {
        setSearchText(String(searchParams.get("q")));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.devices !== typeof undefined) {
      setCurrentState(state.devices);
    }

    if (state.nextPage.page !== undefined) {
      setOffset(state.nextPage.page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.devices, state.nextPage]);

  useEffect(() => {
    async function loadData() {
      const devices = await deviceData;
      dispatch({ type: ActionType.SET_TOTAL_RECORDS, payload: devices.total });
      setOffset(devices.offset);
      if (devices.offset && state.nextPage[page] === undefined) {
        dispatch({ type: ActionType.NEXT_PAGE, payload: devices.offset });
      }
      if (state.devices.length !== 0) {
        const currentDevices = state.devices;
        const nextDevices = version === "v2" ? devices.items : devices.devices;
        devices.devices = currentDevices.concat(nextDevices);
      }
      // Merges devices and branches arrays based on matching branch_id
      let deviceMerge = [];

      if (devices.length !== 0) {
        if (version === "v2") {
          deviceMerge = devices.items?.map((t1) => ({
            ...t1,
            ...branches.find((t2) => t2.branch_id === t1.branch_id),
          }));
          if (devices.total) {
            setTotalRowsDevices(devices.total);
          }
        } else {
          deviceMerge = devices.devices?.map((t1) => ({
            ...t1,
            ...branches.find((t2) => t2.branch_id === t1.branch_id),
          }));
        }
      }

      // checks that branchName key exists in the array if not adds it
      if (deviceMerge) {
        const deviceList: Devices = deviceMerge.map((device) => {
          if (device.branchName === undefined) {
            device.branchName = "";
          }
          if (device.modified_at && device.last_otp_at) {
            const date1 = moment(device.modified_at);
            const date2 = moment(device.last_otp_at);
            if (date1.isSameOrBefore(date2)) {
              device.modified_at = device.last_otp_at;
              device.modified_by = device.last_otp_by;
            }
          }
          return device;
        });
        dispatch({ type: ActionType.SET_DEVICES, payload: deviceList });
      }
    }

    deviceData && loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceData]);

  useEffect(() => {
    async function loadNextData() {
      updatePage(limit);
    }
    offset && loadNextData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  useEffect(() => {
    // trigger search on browser back button click, update page results with search data
    if (searchParams.get("q")) {
      setSearchText(searchParams.get("q"));
    } else {
      setSearchText("");
    }
    dispatch({ type: ActionType.SET_DEVICES, payload: [] });
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, searchText, deviceIds]);

  const refreshDevices = () => {
    !deviceData ? getDevices(`${version}GetDevices`) : refresh();
  };

  const retrieveDevices = () => {
    // reset limit and page on devices load
    setLimit(undefined);
    setPage(1);
    dispatch({ type: ActionType.SET_DEVICES, payload: [] });
    if (!deviceData) {
      getDevices(`${version}GetDevices`);
    }
    refresh();
  };

  const newPage = (page, limit) => {
    setPage(page);
    let currentPage = state.nextPage[page - 1].page;
    setOffset(currentPage);
    setLimit(limit);

    deviceData ? refresh() : getDevices(`${version}GetDevices`);
  };

  const updatePage = (limit) => {
    setLimit(limit);
    deviceData ? refresh() : getDevices(`${version}GetDevices`);
  };

  const searchDevicesWithId = (deviceIds: string) => {
    setSearchDeviceIds(deviceIds);
  };

  const searchDevices = (searchString: string) => {
    setSearchText(searchString);
    if (searchString) {
      if (searchParams.get("page")) {
        searchParams.set("page", "1");
      }
      searchParams.set("q", searchString);
      setSearchParams(searchParams);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
    searchParams.set("page", page);
    setSearchParams(searchParams);
    if (version === "v2") {
      getDevices("v2GetDevices");
      refresh();
    }
  };

  const handleRowsPerPageChange = (newPerPage, page) => {
    setLimit(newPerPage);
    setPage(page);
    searchParams.set("limit", newPerPage);
    searchParams.set("page", page);
    setSearchParams(searchParams);
    if (version === "v2") {
      getDevices("v2GetDevices");
      refresh();
    }
  };

  const handleSorting = (column: TableColumn<any>, sortDirection: string) => {
    setSortBy(column.sortField);
    setSortDirection(sortDirection);
    searchParams.set("sortBy", column.sortField);
    searchParams.set("sortDirection", sortDirection);
    setSearchParams({ sortBy: column.sortField, sortDirection: sortDirection });
    if (version === "v2") {
      getDevices("v2GetDevices");
      refresh();
    }
  };

  return {
    refreshDevices,
    retrieveDevices,
    newPage,
    updatePage,
    searchDevices,
    searchDevicesWithId,
    searchText,
    totalRowsDevices,
    handlePageChange,
    handleRowsPerPageChange,
    handleSorting,
  };
};
