import validator from "../../../validators/userValidator";
import { v4 as uuidv4 } from "uuid";

export const downcaseFirstLetter = (input: string) => {
  return input ? input.replace(/([a-zA-Z0-9]{1})/, (a) => a.toLowerCase()) : input;
};

export const capitalizeFirstLetter = (input: string) =>
  input ? input.replace(/([a-zA-Z0-9]{1})/, (a) => a.toUpperCase()) : input;

export const underscoreToCamelCase = (input: string) => {
  return input.replace(/_{1,}(.)?/g, (_, b) => (b ? b.toUpperCase() : ""));
};

export const convertCamelCaseToUnderscore = (input: string) => input.replace(/([A-Z])/g, "_$1").toLowerCase();

export const mappedEntries = (data: Record<string, unknown>, mapper: Record<string, string>) =>
  Object.entries(mapper).reduce((obj, [key, value]) => {
    if (data[value] !== undefined) {
      obj[key] = data[value];
    }
    return obj;
  }, {});

export const mappedReverseEntries = (data: Record<string, unknown>, mapper: Record<string, string>) =>
  Object.entries(mapper).reduce((obj, [key, value]) => {
    if (data[key] !== undefined) {
      obj[value] = data[key];
    }
    return obj;
  }, {});

export const mappedCasesEntries = (data) =>
  Object.entries(data).reduce((obj, [key, value]) => ({ ...obj, [downcaseFirstLetter(key)]: value }), {});

export const convertObjUnderscoreToCamelCase = (data) => {
  return Object.entries(data).reduce((obj, [key, value]) => {
    const str = key.match(/^_+/);
    const amended = underscoreToCamelCase(key.replace(/^_+/, ""));
    obj[str ? str + amended : amended] = value;
    return obj;
  }, {});
};

export const convertObjCamelCaseToUnderscore = (data) =>
  Object.entries(data).reduce((obj, [key, value]) => ({ ...obj, [convertCamelCaseToUnderscore(key)]: value }), {});

export const fieldLengthValidation = (inputValue: string, errors: string[], errs: string[], maxLength: number) => {
  if (inputValue.length > maxLength && !errors.includes(errs[0])) {
    return [...errors, errs[0]];
  } else if (inputValue.length <= maxLength && errors.includes(errs[0])) {
    return removeItemsFromArray(errors, errs);
  }
  return errors;
};

export const removeItemsFromArray = (array: string[], item: string[]) => {
  let arr = [...array];
  item.forEach((i) => {
    if (arr.includes(i)) {
      arr.splice(arr.indexOf(i), 1);
    }
  });
  return arr;
};

export const validFields = (data: Record<string, unknown>, fields?: Array<string | boolean>) => {
  return validator.validate({
    ...convertObjCamelCaseToUnderscore(data),
    ...fields,
  });
};

export const addItemsInArray = (array: string[], item: string[]): string[] => {
  if (!item || !item.length) return array;

  let arr = [...array] as string[];
  item.forEach((i) => {
    if (i && !arr.includes(i)) {
      arr = [...arr, i].sort() as string[];
    }
  });
  return arr;
};

export const getCorrelationId = () => uuidv4();
