import moment from "moment";
import { DEFAULT_DATE } from "../../constants";

export const manipulateObject = (row: Record<string, string>, moveField?: string) => {
  let rowsInArray = Object.keys(row)
    .map((k) => [{ key: k, value: row[k] }])
    .flat();

  const index = rowsInArray.findIndex((x) => x.key === moveField);
  if (index !== -1) rowsInArray.push(rowsInArray.splice(index, 1)[0]);
  return rowsInArray;
};

export const formatDate = (date: string, format: string): string => {
  return date && date !== DEFAULT_DATE ? moment(date).format(format) : "N/A";
};

export const getDateFormat = (date: string): string => {
  if (date === "" || date === DEFAULT_DATE) {
    return "";
  }

  return moment(date).format("MMM DD, YYYY HH:mm:ss");
};

export const transformNodeIds = (arr: string[]) => arr.map((v) => ({ label: v, value: v }), {});
