import { Auth } from "aws-amplify";
import { FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import useUserStatus from "../../lib/hooks/useUserStatus";
import { useAppVersion } from "../../lib/hooks/useAppVersion";

const Header: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { version } = useAppVersion();

  const handleLogout = async () => {
    await Auth.signOut();
    navigate(`login`);
  };

  const userStatus = useUserStatus();
  const isLoggedIn = null !== userStatus;

  const v2Header: JSX.Element =
    version === "v2" ? (
      <>
        <div
          className="cursor-pointer sm:-my-px sm:ml-6 sm:flex sm:space-x-8"
          onClick={() => {
            navigate(`/${version}/device-history`);
          }}
        >
          <h3
            className={`inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 border-b-2 ${
              location.pathname === `/${version}/device-history`
                ? "border-pol-red border-b-2"
                : "border-gray-200 border-b"
            }`}
          >
            Device History
          </h3>
        </div>
        <div
          className="cursor-pointer sm:-my-px sm:ml-6 sm:flex sm:space-x-8"
          onClick={() => {
            navigate(`/${version}/alerts`);
          }}
        >
          <h3
            className={`inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 border-b-2  ${
              location.pathname === `/${version}/alerts` ? "border-pol-red border-b-2" : "border-gray-200 border-b"
            }`}
          >
            Alerts
          </h3>
        </div>
      </>
    ) : null;

  return (
    <nav className="bg-white border-b border-gray-200">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex items-center flex-shrink-0">
              <img className="block w-auto h-8" src={Logo} alt="Post Office" />
            </div>
            {isLoggedIn && (
              <>
                <div
                  className={`cursor-pointer sm:-my-px sm:ml-6 sm:flex sm:space-x-8 bg-midnight text-tahiti`}
                  onClick={() => {
                    navigate(`/${version}/devices`);
                  }}
                >
                  <h3
                    className={`inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 border-b-2  ${
                      location.pathname === `/${version}/devices`
                        ? "border-pol-red border-b-2"
                        : "border-gray-200 border-b"
                    }`}
                  >
                    Device Management
                  </h3>
                </div>
                <div
                  className="cursor-pointer sm:-my-px sm:ml-6 sm:flex sm:space-x-8"
                  onClick={() => {
                    navigate(`/${version}/users`);
                  }}
                >
                  <h3
                    className={`inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900   ${
                      location.pathname === `/${version}/users`
                        ? "border-pol-red border-b-2"
                        : "border-gray-200 border-b"
                    }`}
                  >
                    User Management
                  </h3>
                </div>
                {v2Header}
              </>
            )}
          </div>
          <div className="flex items-center ml-6">
            <div className="relative ml-3">
              {isLoggedIn && (
                <button
                  data-test="auth-flow-btn"
                  onClick={handleLogout}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                >
                  Log Out
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
