import { useState, useLayoutEffect, useEffect, useRef, useCallback } from "react";
import { useApi } from "../useApi";
import { useErrorMessage } from "../useErrorMessage";
import validator from "../../../validators/deviceValidator";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { DeviceInfo, BranchInfo } from "../../models";
import {
  fieldLengthValidation,
  removeItemsFromArray,
  mappedReverseEntries,
  mappedEntries,
  mappedCasesEntries,
  addItemsInArray,
} from "../helpers";

import {
  getDeviceManagementApiClient as apiClient,
  getBranchSearchApiClient as branchapiClient,
  getNodeIdsApiClient as nodeApiClient,
} from "../../api-helper/apiHelper";
import { V2DeviceDataProps, ModalData } from "../../../components/types/DeviceModal";
import {
  ERROR_MESSGAES,
  DEVICE_REGISTERED_SUCCESSFULLY,
  DEVICE_UPDATED_SUCCESSFULLY,
  DEVICE_ID_MAX_CHARACTERS,
  DEVICE_NAME_MAX_CHARACTERS,
  NOTES_MAX_CHARACTERS,
  BRANCH_FINDER_ERRORS,
  ModelConfirmation,
  branchMapper,
  deviceMapper,
} from "../../../constants";
import { useAppVersion } from "../useAppVersion";
import {
  Device2StateEnum as DeviceStateEnum,
  Device2DeactivationCodeEnum as DeviceDeactivationCodeEnum,
} from "../../../openapi/device-api/api";
import { getCorrelationId } from "../helpers";

const {
  DEVICE_ID_MAX_CHAR_ERROR,
  DEVICE_ID_MIN_CHAR_ERROR,
  DEVICE_ID_ALREADY_EXISTS_ERROR,
  DEVICE_NAME_MAX_CHAR_ERROR,
  DEVICE_NAME_MIN_CHAR_ERROR,
  BRANCH_ID_ERROR,
  BRANCH_ID_NOT_FOUND_ERROR,
  BRANCH_ID_CHANGE_ERROR,
  DEVICE_TYPE_ERROR,
  DEVICE_DEACTIVATION_CODE_ERROR,
  NODE_ID_MAX_CHAR_ERROR,
  NODE_ID_ERROR,
  NODE_ID_ALREADY_USED_ERROR,
  NOTES_MAX_CHAR_ERROR,
} = ERROR_MESSGAES;

export const V2UseAddEditDevice = ({ pageType }) => {
  const orgBranchIdRef = useRef<string>(undefined);
  const orgNodeIdRef = useRef<string>(undefined);
  const [orgDeviceState, setOrgDeviceState] = useState<string>("");
  const [isDirtyState, setIsDirtyState] = useState<boolean>(false);
  const [isShowStateModal, setShowStateWarning] = useState<boolean>(false);
  const [chooseDeactivationCode, setChooseDeactivationCode] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [branchValid, setBranchValid] = useState<boolean>(true);
  const [branchIdChanged, setBranchIdChanged] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isShowCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [isNullNodeId, setIsNullNodeId] = useState<boolean>(false);
  const location = window.location;
  const toggleCancelWarning = () => setShowCancelModal(!isShowCancelModal);
  const toggleStateWarning = () => setShowStateWarning(!isShowStateModal);
  const { errorFormattor } = useErrorMessage();
  const navigate = useNavigate();
  const editDataFetched = useRef<boolean>(false);
  const params = useParams();
  const { version } = useAppVersion();

  const initBranchData = (): BranchInfo => {
    const data = [
      "branchName",
      "branchOrgUnitCode",
      "branchOrgUnitCodeVersion",
      "branchAddress",
      "branchPostcode",
      "locationTypeName",
      "tradingOpenStatus",
      "tradingSubStatusCode",
      "tradingSubStatusName",
    ].reduce((obj, key) => {
      obj[key] = "";
      return obj;
    }, {}) as BranchInfo;

    return {
      ...data,
      locationTypeCode: null,
    };
  };

  const initDeviceData = (): DeviceInfo => {
    const data = [
      "id",
      "branchId",
      "branchName",
      "type",
      "name",
      "notes",
      "nodeId",
      "deviceState",
      "deactivationCode",
    ].reduce((obj, key) => {
      obj[key] = "";
      return obj;
    }, {}) as DeviceInfo;

    return {
      ...data,
      ...initBranchData(),
    };
  };

  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>(initDeviceData());
  const correlationId = useRef<string>(getCorrelationId());
  const headers = { headers: { "X-Correlation-ID": correlationId.current } };

  const addUpdateDeviceParams = {
    ...mappedReverseEntries(deviceInfo as unknown as Record<string, unknown>, deviceMapper),
    ...mappedReverseEntries(deviceInfo as unknown as Record<string, unknown>, branchMapper),
    state: DeviceStateEnum.Active,
    deactivation_code: DeviceDeactivationCodeEnum.FaultyDevice,
  };

  const putApiOptions = () => {
    let { nodeId, ...rest } = deviceInfo;
    const info = { ...rest };
    return [
      deviceInfo.id,
      {
        ...mappedReverseEntries(info as unknown as Record<string, unknown>, deviceMapper),
        state: deviceInfo.state || DeviceStateEnum.Active,
        ...(nodeId && !isNullNodeId && { node_id: nodeId }),
        deactivation_code:
          info.state !== DeviceStateEnum.Inactive ? DeviceDeactivationCodeEnum.FaultyDevice : info.deactivationCode,
      },
      headers,
    ];
  };

  // get device details
  const [
    { data: deviceDetailApiResponse, error: deviceDetailApiError, statusCode: deviceDetailApiStatus },
    getDeviceList,
  ] = useApi(apiClient, [params.deviceID, headers], undefined, "GET");

  // add or update device
  const [
    { data: deviceApiResponse, status: deviceApiStatus, error: deviceApiError, statusCode: deviceApiStatusCode },
    fetchDevice,
    refreshDevice,
  ] = useApi(apiClient, pageType === "ADD" ? [addUpdateDeviceParams, headers] : putApiOptions(), undefined, "");

  // get branch data
  const [{ data: branchData, error: branchError, statusCode: status }, getBranchData, branchRefresh] = useApi<
    typeof branchapiClient
  >(branchapiClient, ["Device_Manager", deviceInfo.branchId, headers], undefined, "GET");

  // get node ids
  const [{ data: nodeIdsData, error: nodeIdsError }, getnodeIdsData, nodeIdsRefresh] = useApi<typeof nodeApiClient>(
    nodeApiClient,
    [deviceInfo.branchId, headers],
    undefined,
    "GET",
  );

  const onDeviceDataChange = (field: string, selected: any, event?: any) => {
    setDeviceInfo({ ...deviceInfo, [field]: selected });
    setIsDirty(true);
    switch (field) {
      case "id":
        maxCharValidation(selected, [DEVICE_ID_MAX_CHAR_ERROR, DEVICE_ID_MIN_CHAR_ERROR], DEVICE_ID_MAX_CHARACTERS);
        return;
      case "name":
        maxCharValidation(
          selected,
          [DEVICE_NAME_MAX_CHAR_ERROR, DEVICE_NAME_MIN_CHAR_ERROR],
          DEVICE_NAME_MAX_CHARACTERS,
        );
        return;
      case "nodeId":
        removeValidationErrors([NODE_ID_ERROR, NODE_ID_MAX_CHAR_ERROR]);
        return;
      case "branchId":
        setDeviceInfo({ ...deviceInfo, [field]: selected.toUpperCase() });
        setBranchValid(false);
        return;
      case "branchName":
        maxCharValidation(
          selected,
          [DEVICE_NAME_MAX_CHAR_ERROR, DEVICE_NAME_MIN_CHAR_ERROR],
          DEVICE_NAME_MAX_CHARACTERS,
        );
        return;
      case "type":
        setIsNullNodeId(false);
        removeValidationErrors([DEVICE_TYPE_ERROR]);
        return;
      case "state":
        if (selected === DeviceStateEnum.Active) {
          setDeviceInfo({ ...deviceInfo, state: selected, deactivationCode: DeviceDeactivationCodeEnum.FaultyDevice });
        }
        setChooseDeactivationCode(false);
        setIsDirtyState(false);
        if (deviceDetailApiResponse.state !== selected) {
          setIsDirtyState(true);
        }
        return;
      case "deactivationCode":
        setChooseDeactivationCode(true);
        removeValidationErrors([DEVICE_DEACTIVATION_CODE_ERROR]);
        return;
      case "notes":
        maxCharValidation(selected, [NOTES_MAX_CHAR_ERROR], NOTES_MAX_CHARACTERS);
        return;
      default:
        return;
    }
  };

  const maxCharValidation = (inputValue: string, errors: string[], maxLength: number) => {
    const errs = fieldLengthValidation(inputValue, validationErrors, errors, maxLength);
    setValidationErrors(errs);
  };

  const handleCancelButton = () => {
    if (isDirty) {
      toggleCancelWarning();
      navigate(-1);
    } else {
      navigate(-1);
    }
  };

  const handleUpdateButton = () => {
    if (isDirtyState) {
      toggleStateWarning();
    } else {
      handleEditDevice();
    }
  };

  const getNodeId = (): string => {
    const orgBid = orgBranchIdRef.current;
    const bid = deviceInfo.branchId;
    const nid = deviceInfo.nodeId;
    if (orgBid !== bid && !nid) {
      return "";
    } else if (orgBid === bid && nid) {
      return nid;
    }

    return orgBid === bid ? orgNodeIdRef.current : nid;
  };

  const getNodeIds = useCallback(async () => {
    nodeIdsData || nodeIdsError ? nodeIdsRefresh() : getnodeIdsData("v2GetNodeid");
    await nodeIdsData;
  }, [nodeIdsData, nodeIdsError, nodeIdsRefresh, getnodeIdsData]);

  const validFields = (fields?: Record<string, string | boolean>) => {
    const skipValidationFor = deviceInfo.state === DeviceStateEnum.Inactive ? ["node_id"] : [];
    setValidationErrors([]);

    const { nodeId } = deviceInfo;
    if (!nodeId) {
      setDeviceInfo({ ...deviceInfo, nodeId: getNodeId() });
    }

    return validator.validate(
      {
        ...mappedReverseEntries(deviceInfo as unknown as Record<string, unknown>, deviceMapper),
        ...(!nodeId && { node_id: getNodeId() }),
        ...fields,
        branchValid,
      },
      skipValidationFor,
    );
  };

  const handleAddDevice = async () => {
    const errors = validFields({ orgDeviceState, chooseDeactivationCode });
    if (errors && errors.length === 0) {
      deviceApiResponse || deviceApiError ? refreshDevice() : fetchDevice(`${version}PostDevice`);
      await deviceApiResponse;
    } else {
      setValidationErrors(errors);
    }
  };

  const handleEditDevice = async () => {
    const errors = validFields({ orgDeviceState, chooseDeactivationCode });
    if (errors && !errors.length) {
      if (deviceInfo.nodeId === "") {
        const { nodeId, ...rest } = deviceInfo;
        setDeviceInfo(rest as DeviceInfo);
      }
      deviceApiResponse || deviceApiError ? refreshDevice() : fetchDevice(`${version}PutDevice`);
      await deviceApiResponse;
    } else {
      setValidationErrors(errors);
    }
  };

  useEffect(() => {
    if (location.hash.includes("#/device")) {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params.deviceID) {
      getDeviceList(`${version}GetDevice`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!isShowCancelModal && isDirty) {
      window.history.pushState(null, null, window.location.pathname + "#/device");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowCancelModal]);

  useEffect(() => {
    if (isDirty) {
      window.history.pushState(null, null, window.location.pathname + "#/device");
      window.addEventListener("popstate", onBackButtonEvent);
      return () => {
        window.removeEventListener("popstate", onBackButtonEvent);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  const successResponse = (type: string, messge: string, theme?: string) => {
    toast[type](messge, { theme: theme });
    setIsDirty(false);
    setTimeout(() => {
      navigate(`/${version}/devices`, { replace: true });
    }, 1000);
  };

  const displayResult = () => {
    if (deviceApiResponse !== typeof undefined && deviceApiStatusCode === 201) {
      successResponse("info", DEVICE_REGISTERED_SUCCESSFULLY);
    } else if (deviceApiResponse !== typeof undefined && deviceApiStatusCode === 200) {
      successResponse("success", DEVICE_UPDATED_SUCCESSFULLY, "light");
    } else if (deviceApiStatusCode === 409 && deviceApiStatus === "error") {
      setValidationErrors([NODE_ID_ALREADY_USED_ERROR]);
    } else if (deviceApiError && deviceApiStatus === "error") {
      if (deviceApiError.includes("UsernameExistsException")) {
        setValidationErrors([DEVICE_ID_ALREADY_EXISTS_ERROR]);
        toast.error(DEVICE_ID_ALREADY_EXISTS_ERROR);
      } else {
        setValidationErrors([deviceApiError]);
        errorFormattor(deviceApiError).forEach((i) => {
          toast.error(i);
        });
      }
    }
  };

  useLayoutEffect(() => {
    displayResult();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceApiResponse, deviceApiError, deviceApiStatus, deviceApiStatusCode]);

  useLayoutEffect(() => {
    if (deviceDetailApiError) {
      toast.error(deviceDetailApiError);
    } else if (deviceDetailApiStatus) {
      searchBranch();
      if (!orgBranchIdRef.current) {
        orgBranchIdRef.current = deviceDetailApiResponse.branch_id;
        orgNodeIdRef.current = deviceDetailApiResponse.node_id;
      }
      setDeviceInfo({
        ...deviceInfo,
        ...mappedEntries(deviceDetailApiResponse, deviceMapper),
        ...mappedEntries(deviceDetailApiResponse, branchMapper),
      });
      setIsNullNodeId(false);
      setOrgDeviceState(deviceDetailApiResponse.state || DeviceStateEnum.Active);
      setTimeout(() => {
        editDataFetched.current = true;
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceDetailApiResponse, deviceDetailApiStatus, deviceDetailApiError]);

  useLayoutEffect(() => {
    if (status === 200) {
      getNodeIds();
      removeValidationErrors([BRANCH_ID_CHANGE_ERROR, BRANCH_ID_ERROR, BRANCH_ID_NOT_FOUND_ERROR]);
      if (branchData.Data && branchData.Data.length > 0) {
        const data = branchData.Data[0];

        setDeviceInfo({
          ...deviceInfo,
          ...mappedCasesEntries(data),
        });
        setBranchValid(true);
      } else {
        resetBranchData();
        setValidationErrors([...validationErrors, BRANCH_ID_NOT_FOUND_ERROR]);
      }
    } else {
      toast.error(BRANCH_FINDER_ERRORS[`ERROR_${branchError}`] ?? branchError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchData, status, branchError]);

  useEffect(() => {
    if (isNullNodeId) {
      setIsNullNodeId(false);
      setDeviceInfo({
        ...deviceInfo,
        nodeId: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNullNodeId]);

  useEffect(() => {
    //Do not set dirty, if data is filled from edit api
    if (pageType === "EDIT" && !editDataFetched.current) {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceInfo, pageType]);

  const removeValidationErrors = (errors: string[]) => {
    const errs = removeItemsFromArray(validationErrors, errors);
    setValidationErrors(errs);
  };

  const resetBranchData = () => {
    setDeviceInfo({
      ...deviceInfo,
      ...initBranchData(),
    });
  };

  const confirmCancel = (confirmation: string) => {
    toggleCancelWarning();
    if (confirmation === ModelConfirmation.Yes) {
      setIsDirty(false);
      navigate(-1);
    }
  };

  const confirmStateChanges = (confirmation: string) => {
    toggleStateWarning();
    if (confirmation === ModelConfirmation.Yes) {
      setShowStateWarning(false);
      handleEditDevice();
    } else {
      toggleStateWarning();
    }
  };

  const onBackButtonEvent = (event: PopStateEvent) => {
    event.preventDefault();
    if (isDirty) {
      toggleCancelWarning();
    } else {
      navigate(`/${version}/devices`);
    }
  };

  const searchBranch = useCallback(async () => {
    setIsNullNodeId(true);
    if (orgBranchIdRef.current && deviceInfo.branchId && orgBranchIdRef.current !== deviceInfo.branchId) {
      setBranchIdChanged(true);
    } else {
      setBranchIdChanged(false);
    }

    branchData || branchError ? branchRefresh() : getBranchData("apiconsumerBranchDetailsV01Get");
    await branchData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchData, branchError, branchRefresh, getBranchData]);

  const modalData: ModalData = {
    isShowCancelModal,
    isShowStateModal,
    toggleCancelWarning,
    confirmCancel,
    toggleStateWarning,
    confirmStateChanges,
  };

  const deviceData: V2DeviceDataProps = {
    modalStatus: pageType,
    ...deviceInfo,
    orgBranchId: orgBranchIdRef.current,
    orgNodeId: orgNodeIdRef.current,
    nodeIds:
      nodeIdsData && nodeIdsData.node_ids
        ? addItemsInArray(nodeIdsData.node_ids, [deviceInfo.nodeId, branchIdChanged ? undefined : orgNodeIdRef.current])
        : undefined,
    deviceState: deviceInfo.state,
    deactivationCode:
      orgDeviceState === DeviceStateEnum.Active || orgDeviceState === ""
        ? ""
        : deviceInfo.deactivationCode || deviceDetailApiResponse?.deactivation_code,
  };

  return {
    handleAddDevice,
    handleUpdateButton,
    handleCancelButton,
    searchBranch,
    onDeviceDataChange,
    modalData,
    deviceData,
    validationErrors,
  };
};
