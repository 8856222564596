import React, { FC, useContext, useEffect, useState } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";

import { useNavigate, useLocation } from "react-router-dom";
import { awsSession } from "../../lib/AWS";
import { AppContext } from "../../context/store";
import { ActionType } from "../../context/reducer";

const Authenticated: FC = (props) => {
  const [authenticated, setAuthenticated] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { state, dispatch } = useContext(AppContext);
  let ldClient = useLDClient();
  // if SDK ever loses connectivity, it will continue to attempt to reconnect until it can
  ldClient.on('error', (e)=>{/*do nothing*/}); // supressing network errors when it can't connect

  useEffect(() => {
    const checkSession = async () => {
      try {
        const session = await awsSession();

        ldClient.identify(
          {
            kind: "user",
            key: session.username,
            email: session.email,
          },
          null,
          () => {
            console.debug("New context's flags available");
          },
        );

        setAuthenticated(true);
        if (!state.userRole) {
          dispatch({
            type: ActionType.SET_USER_ROLE,
            payload: session.role,
          });
        }
      } catch (err) {
        setAuthenticated(false);
      }
    };

    checkSession();
  });

  if (authenticated) {
    return props.children as React.ReactElement;
  }

  navigate(`/login?return=${location.pathname}`, { replace: true });
  return null;
};

export default Authenticated;
