import { useState } from "react";
import { TypePasswordHint } from "../../pages/PasswordHint";

export const passwordHintTitle =
  "Should contain 1 number \nShould contain 1 uppercase\nShould contain 1 lowercase letter\nShould contain 1 special character\nShould be at least 15 characters long";

interface PasswordHintCallback {
  passwordHints: TypePasswordHint;
  onPasswordChange: (e: { target: { value: string } }) => void;
}

const lowerCaseRegex = /[a-z]/;
const upperCaseRegex = /[A-Z]/;
const numbersRegex = /[0-9]/;
const specialCharRegex = /[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/;

export const usePasswordHint = (initialState: TypePasswordHint): PasswordHintCallback => {
  const [passwordHints, setPasswordHints] = useState<TypePasswordHint>(initialState);

  const onPasswordChange = (e: { target: { value: string } }) => {
    const text = e.target.value;

    const hints = {
      lowercase: lowerCaseRegex.test(text),
      uppercase: upperCaseRegex.test(text),
      number: numbersRegex.test(text),
      length: text.length >= 15,
      specialChar: specialCharRegex.test(text),
    };

    setPasswordHints(hints);
  };

  return { passwordHints, onPasswordChange };
};
