import { FC } from "react";
import { NO_RESULT_FOUND } from "../constants";

export interface NoResultProps {
  message?: string;
}

const NoResultComponent: FC<NoResultProps> = ({ message }): JSX.Element => {
  return <div className="no-results">{message || NO_RESULT_FOUND}</div>;
};

export default NoResultComponent;
