import { FC, useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { Authenticator, Heading, useTheme } from "@aws-amplify/ui-react";
import PasswordHint from "./PasswordHint";
import { passwordHintTitle, usePasswordHint } from "../lib/hooks/usePasswordHint";
import ForceNewPassword from "./ForceNewPassword";
import { useAppVersion } from "../lib/hooks/useAppVersion";

const Login: FC = () => {
  const location = useLocation();
  const { version } = useAppVersion();
  const DEFAULT_REDIRECT: string = `/${version}/devices`;

  const [showPasswordHint, setShowPasswordHint] = useState<boolean>(false);

  const { passwordHints, onPasswordChange } = usePasswordHint({
    lowercase: false,
    uppercase: false,
    number: false,
    length: false,
    specialChar: false,
  });

  const returnUrl = location.search
    .substring(1)
    .split("&")
    .find((s) => s.startsWith("return="));

  const navigateTo = returnUrl ? returnUrl.split("=")[1] : DEFAULT_REDIRECT;

  const components = {
    SignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={4}>
            Sign in to your account
          </Heading>
        );
      },
    },
    ConfirmResetPassword: {
      Footer() {
        return <PasswordHint passwordHints={passwordHints} show={showPasswordHint} />;
      },
    },
    ForceNewPassword: {
      FormFields() {
        return <ForceNewPassword />;
      },
    },
  };

  const formFields = {
    confirmResetPassword: {
      password: {
        placeholder: "New Password",
        title: passwordHintTitle,
        onChange: onPasswordChange,
        onBlur: () => {
          setShowPasswordHint(false);
        },
        onFocus: () => {
          setShowPasswordHint(true);
        },
      },
    },
  };

  return (
    <Authenticator loginMechanisms={["email"]} formFields={formFields} components={components}>
      {() => <Navigate replace to={navigateTo} />}
    </Authenticator>
  );
};

export default Login;
