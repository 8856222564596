export interface PageState<DataType, ColumnType> {
  status: "loading" | "not_loading";
  perPage: number;
  page: number;
  data: DataType[] | undefined;
  totalRows: number;
  query: string | undefined;
  columns: ColumnType[];
  sortColumn: string | undefined;
  sortOrder: string | undefined;
}

export enum PageActionNames {
  CHANGE_PER_PAGE = "CHANGE_PER_PAGE",
  CHANGE_PAGE = "CHANGE_PAGE",
  CHANGE_QUERY = "CHANGE_QUERY",
  CHANGE_PARAMS = "CHANGE_PARAMS",
  CHANGE_SORTING = "CHANGE_SORTING",
  SET_DATA = "SET_DATA",
}

export interface ChangePerPageAction {
  type: PageActionNames.CHANGE_PER_PAGE;
  payload: {
    perPage: number;
    page: number;
  };
}

export const changePerPageAction = (perPage: number, page: number): ChangePerPageAction => {
  return {
    type: PageActionNames.CHANGE_PER_PAGE,
    payload: {
      perPage,
      page,
    },
  };
};

export interface ChangePageAction {
  type: PageActionNames.CHANGE_PAGE;
  payload: {
    page: number;
  };
}

export const changePageAction = (page: number): ChangePageAction => {
  return {
    type: PageActionNames.CHANGE_PAGE,
    payload: {
      page,
    },
  };
};

interface ChangeParamsAction {
  type: PageActionNames.CHANGE_PARAMS;
  payload: {
    perPage?: number;
    page?: number;
    query?: string;
    sortBy?: string;
    sortDirection?: string;
  };
}

export const changeParamsAction = (params): ChangeParamsAction => {
  const perPage = params.perPage;
  const page = params.page;
  const query = params.query;
  const sortBy = params.sortBy;
  const sortDirection = params.sortDirection;
  return {
    type: PageActionNames.CHANGE_PARAMS,
    payload: {
      perPage,
      page,
      query,
      sortBy,
      sortDirection,
    },
  };
};

interface ChangeQueryAction {
  type: PageActionNames.CHANGE_QUERY;
  payload: {
    query: string;
  };
}

export const changeQueryAction = (query: string): ChangeQueryAction => {
  return {
    type: PageActionNames.CHANGE_QUERY,
    payload: {
      query,
    },
  };
};

interface ChangeSortAction {
  type: PageActionNames.CHANGE_SORTING,
  payload: {
    column: string,
    orderBy: string
  };
}

export const changeSortAction = (column: string, orderBy: string): ChangeSortAction => {
  return {
    type: PageActionNames.CHANGE_SORTING,
    payload: {
      column,
      orderBy,
    }
  }
};

interface SetDataAction<DataType> {
  type: PageActionNames.SET_DATA;
  payload: {
    data: DataType[];
    totalRows: number;
  };
}

export const setDataAction = <DataType>(data: DataType[], totalRows: number): SetDataAction<DataType> => {
  return {
    type: PageActionNames.SET_DATA,
    payload: {
      data,
      totalRows,
    },
  };
};

type PageAction<DataType> =
  | ChangePerPageAction
  | ChangePageAction
  | SetDataAction<DataType>
  | ChangeQueryAction
  | ChangeParamsAction
  | ChangeSortAction;

export const pageReducer = <DataType, ColumnType>(
  state: PageState<DataType, ColumnType>,
  action: PageAction<DataType>,
): PageState<DataType, ColumnType> => {
  if (action) {
    switch (action.type) {
      case PageActionNames.CHANGE_PER_PAGE:
        return {
          ...state,
          status: "loading",
          perPage: action.payload.perPage,
          page: action.payload.page,
        };
      case PageActionNames.SET_DATA:
        return {
          ...state,
          status: "not_loading",
          totalRows: action.payload.totalRows,
          data: action.payload.data,
        };
      case PageActionNames.CHANGE_PAGE:
        return {
          ...state,
          status: "loading",
          page: action.payload.page,
        };
      case PageActionNames.CHANGE_QUERY:
        return {
          ...state,
          status: "loading",
          query: action.payload.query,
        };
      case PageActionNames.CHANGE_PARAMS:
        return {
          ...state,
          status: "loading",
          perPage: action.payload.perPage,
          page: action.payload.page,
          query: action.payload.query,
          sortColumn: action.payload.sortBy,
          sortOrder: action.payload.sortDirection,
        };
        case PageActionNames.CHANGE_SORTING:
          return {
            ...state,
            status: "loading",
            sortColumn: action.payload.column,
            sortOrder: action.payload.orderBy,
          }
    }
  }
};
