import { FC } from "react";
import Page from "../components/layout/Page";
import UserAddModal from "../components/UserAddEditModal";
import V2UserAddModal from "../components/v2/V2UserAddEditModal";
import { EDIT_USER, Add_USER } from "../constants";
import { useParams } from "react-router-dom";
import { useAppVersion } from "../lib/hooks/useAppVersion";

const AddEditUser: FC = () => {
  const { version } = useAppVersion();
  const params = useParams();

  let modalHeader;
  if (version === "v1") {
    modalHeader = params.username ? EDIT_USER : Add_USER;
  } else {
    modalHeader = params.id ? EDIT_USER : Add_USER;
  }
  return (
    <Page>
      <div className="max-w-7xl mx-auto py-8 px-5 bg-white">
        <div className="flex px-4 py-3 sm:px-6 sm:flex">
          <h1 className="flex-grow ml-3 text-xl font-bold leading-9 text-gray-900">{modalHeader}</h1>
        </div>
        {version === "v2" ? <V2UserAddModal /> : <UserAddModal />}
      </div>
    </Page>
  );
};

export default AddEditUser;
