import { PasswordField } from "@aws-amplify/ui-react";
import React, { FC, useState } from "react";
import { usePasswordHint, passwordHintTitle } from "../lib/hooks/usePasswordHint";
import PasswordHint from "./PasswordHint";

const ForceNewPassword: FC = () => {
  const passwordRef = React.useRef(null);
  const confirmPasswordRef = React.useRef(null);
  const [showPasswordHint, setShowPasswordHint] = useState<boolean>(false);
  const [passwordMatched, setPasswordMatched] = useState<boolean>(true);
  const { passwordHints, onPasswordChange } = usePasswordHint({
    lowercase: false,
    uppercase: false,
    number: false,
    length: false,
    specialChar: false,
  });

  const matchPassword = () => {
    if (confirmPasswordRef.current.value) {
      setPasswordMatched(passwordRef.current.value === confirmPasswordRef.current.value);
    }
  };

  return (
    <>
      <PasswordField
        label="Password"
        id="password"
        placeholder="Password"
        name="password"
        title={passwordHintTitle}
        onChange={onPasswordChange}
        onBlur={() => {
          matchPassword();
          setShowPasswordHint(false);
        }}
        onFocus={() => setShowPasswordHint(true)}
        ref={passwordRef}
        required
      />
      <PasswordField
        label="Confirm Password"
        id="confirm_password"
        placeholder="Please confirm your Password"
        name="confirm_password"
        onBlur={matchPassword}
        ref={confirmPasswordRef}
        required
      />
      {!passwordMatched && <p className="amplify-text amplify-text--error">Password confirmation does not match</p>}
      <PasswordHint passwordHints={passwordHints} show={showPasswordHint} />
    </>
  );
};

export default ForceNewPassword;
