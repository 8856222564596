import { FC } from "react";

export interface RecordListProps {
  list: Record<string, string>[];
  valueType: string;
}

const RecordListComponent: FC<RecordListProps> = ({ list, valueType }): JSX.Element => {
  return (
    <div className="flex-column flex-start my-2.5 mx-0">
      {list.map((attr) => {
        return valueType === "key" ? (
          <div key={`${attr.key}_key`}>
            {attr.key}
            <br />
            <br />
          </div>
        ) : (
          <div key={`${attr.value}_value`}>
            {attr.value}
            <br />
            <br />
          </div>
        );
      })}
    </div>
  );
};

export default RecordListComponent;
