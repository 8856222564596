import React, { FC, useMemo, useRef } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { Alerts as AlertsRecord } from "../openapi/device-api/api";
import { manipulateObject, formatDate } from "./helpers";
import RecordListComponent from "./RecordListComp";
import AlertsIconComponent from "./AlertsIconComp";
import { AlertsTypeEnum } from "../openapi/device-api/api";
import NoResultComponent from "./NoResultComp";
import { useSearchParams } from "react-router-dom";
import { ROWS_PER_PAGE_V2 } from "../constants";
import { SearchBox } from "./table/searchBox";
import { loadingSpinner } from "./loadingSpinner";

export interface DeviceAlertsTableProps {
  rows: AlertsRecord[];
  totalRows: number | undefined;
  handlePageChange?: (page: number) => void;
  handleRowsPerPageChange?: (newPage: number, page: number) => void;
  searchAlertsCallback?: (search: string) => void;
}

enum ALERT_FIELDS_MAPPING {
  branch_address = "Branch Address",
  branch_id = "Branch ID",
  branch_name = "Branch Name",
  branch_org_unit_code = "Branch Org Unit Code",
  branch_postcode = "Branch Postcode",
  location_type_code = "Location Type Code",
  location_type_name = "Location Type Name",
  trading_open_status = "Trading Open Status",
  trading_sub_status_code = "Trading Sub Status Code",
  trading_sub_status_name = "Trading Sub Status Name",
}

export const mappingObject = (data: Record<string, string>[]) => {
  const mappedData = data.reduce((obj, item) => {
    obj.push({ key: ALERT_FIELDS_MAPPING[item.key], value: item.value });
    return obj;
  }, []);

  return mappedData;
};

const objectRowStyle: Readonly<{ [key: string]: string }> = {
  "flex-direction": "column",
  "flex-wrap": "wrap",
  "align-items": "flex-start",
};

const rowStyle: Readonly<{ [key: string]: string }> = {
  "align-items": "flex-start",
  margin: "10px 0 10px 0;",
};

const colNames = [
  {
    name: "Alert Type",
    selector: (row) => row.type,
    maxWidth: "110px",
    sortable: true,
    cell: (row: AlertsRecord) => <AlertsIconComponent type={row.type as AlertsTypeEnum} />,
    style: rowStyle,
  },
  {
    name: "Branch ID",
    selector: (row) => row.branch_id,
    sortable: true,
    maxWidth: "120px",
    style: rowStyle,
  },
  {
    name: "Terminal Type",
    selector: (row) => row.device_type,
    sortable: true,
    maxWidth: "130px",
    wrap: true,
    style: rowStyle,
  },
  {
    name: "Attribute Modified",
    selector: (row) => row.after,
    maxWidth: "210px",
    wrap: true,
    cell: (row: AlertsRecord) => (
      <RecordListComponent
        list={mappingObject(manipulateObject(row.after as unknown as Record<string, string>, "branch_address"))}
        valueType="key"
      />
    ),
    style: objectRowStyle,
  },
  {
    name: "Before",
    selector: (row) => row.before,
    maxWidth: "400px",
    wrap: true,
    cell: (row: AlertsRecord) => (
      <RecordListComponent
        list={manipulateObject(row.before as unknown as Record<string, string>, "branch_address")}
        valueType="value"
      />
    ),
    style: objectRowStyle,
  },
  {
    name: "After",
    selector: (row) => row.after,
    maxWidth: "400px",
    wrap: true,
    cell: (row: AlertsRecord) => (
      <RecordListComponent
        list={manipulateObject(row.after as unknown as Record<string, string>, "branch_address")}
        valueType="value"
      />
    ),
    style: objectRowStyle,
  },
  {
    name: "Alert Generated Date",
    selector: (row) => row.generated_at,
    cell: (row: AlertsRecord) => {
      return formatDate(row.generated_at as string, "MMM DD, YYYY HH:mm:ss");
    },
    wrap: true,
    sortable: true,
    maxWidth: "185px",
    style: rowStyle,
  },
] as unknown as TableColumn<AlertsRecord>[];

const DeviceAlertsTableComp: FC<DeviceAlertsTableProps> = ({
  rows,
  totalRows,
  handlePageChange,
  handleRowsPerPageChange,
  searchAlertsCallback,
}): JSX.Element => {
  let [searchParams, setSearchParams] = useSearchParams();
  const limit = searchParams.get("limit");
  const searchString = searchParams.get("q");

  const searchRef = useRef<HTMLInputElement>();

  const handleKeyUp = (e: { key: string }) => {
    if (
      (e.key === "Enter" && searchRef.current.value && searchRef.current.value.length > 2) ||
      searchRef.current.value.length === 0
    ) {
      searchAlertsCallback(searchRef.current.value);
    }
  };


  const onReset = () => {
    searchParams.delete("q");
    setSearchParams(searchParams);
    searchRef.current.value = null;
    searchAlertsCallback("");
  };

  const subHeaderComponent = useMemo((): JSX.Element => {
    return (
      <div className="inline-flex items-end w-1/4 py-4">
        <SearchBox
            ref={searchRef}
            onKeyUp={handleKeyUp}
            onReset={onReset}
            defaultValue={searchString}
          />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRef, searchString]);

  return (
    <div data-testid="dataTable">
      <DataTable
        className="data-table"
        columns={colNames}
        data={rows}
        highlightOnHover
        noDataComponent={<NoResultComponent message="There are no records to display" />}
        subHeader
        subHeaderComponent={subHeaderComponent}
        persistTableHead
        defaultSortAsc
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={limit ? Number(limit) : ROWS_PER_PAGE_V2}
        onChangeRowsPerPage={handleRowsPerPageChange}
        onChangePage={handlePageChange}
        {...(totalRows === undefined
          ? {
              progressPending: true,
              progressComponent: loadingSpinner("Loading devices alerts..."),
            }
          : "")}
      />
    </div>
  );
};

export default DeviceAlertsTableComp;
