import React, { FC, useContext, useLayoutEffect } from "react";
import Page from "../components/layout/Page";
import DeviceAlertsTableComp from "../components/DeviceAlertsTableComp";
import { AppContext } from "../context/store";
import { useDeviceAlerts } from "../lib/hooks/useDeviceAlerts";

const DeviceAlerts: FC = (): JSX.Element => {
  const { state } = useContext(AppContext);
  const { retrieveDeviceAlerts, searchAlerts, totalRows, handlePageChange, handleRowsPerPageChange } = useDeviceAlerts();

  useLayoutEffect(() => {
    retrieveDeviceAlerts();
  }, [retrieveDeviceAlerts, state]);

  return (
    <Page>
      <DeviceAlertsTableComp
        rows={state.deviceAlerts}
        totalRows={totalRows}
        searchAlertsCallback={searchAlerts}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
    </Page>
  );
};

export default DeviceAlerts;
