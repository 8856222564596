const LIGHT_GRAY = "#E5E7EB";
const DARK_GRAY = "#111827";

export const customStyles = {
  // For the select itself (not the options)
  control: (styles, { isDisabled }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? LIGHT_GRAY : "white",
    };
  },
  singleValue: (provided: any) => ({
    ...provided,
    color: DARK_GRAY,
  }),
};
