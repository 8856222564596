import { Context, createContext, ReactNode, useContext } from "react";
import { useDevices } from "./hooks/useDevices";
import { TableColumn } from "react-data-table-component";

type GlobalState = {
  retrieveDevices: () => void;
  searchDevices: (query: string) => void;
  searchText: string;
  totalRowsDevices?: number;
  handlePageChange?: (page: number) => void;
  handleRowsPerPageChange?: (newPerPage: number, page: number) => void;
  handleSorting?: (column: TableColumn<any>, sortDirection: string) => void;
};

const GlobalStateContext: Context<GlobalState> = createContext<GlobalState>({
  retrieveDevices: () => {},
  searchDevices: () => {},
  searchText: null,
  totalRowsDevices: undefined,
  handlePageChange: () => {},
  handleRowsPerPageChange: () => {},
  handleSorting: () => {},
});

export const useGlobalState = () => {
  const ctx = useContext(GlobalStateContext);

  if (!ctx) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider component");
  }

  return ctx;
};

export function GlobalStateProvider({ children }: { children: ReactNode }): JSX.Element {
  const {
    retrieveDevices,
    searchDevices,
    searchText,
    totalRowsDevices,
    handlePageChange,
    handleRowsPerPageChange,
    handleSorting,
  } = useDevices();
  return (
    <GlobalStateContext.Provider
      value={{
        retrieveDevices,
        searchDevices,
        searchText,
        totalRowsDevices,
        handlePageChange,
        handleRowsPerPageChange,
        handleSorting,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
}
