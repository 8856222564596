import { useState, useLayoutEffect, useEffect, useRef } from "react";
import { useApi } from "./useApi";
import useDidMountEffect from "./useDidMountEffect";
import { useErrorMessage } from "./useErrorMessage";
import validator from "../../validators/deviceValidator";
import { toast } from "react-toastify";
import { ModelConfirmation } from "../../constants";
import { useModal } from "./useModal";
import { useNavigate, useParams } from "react-router-dom";

import {
  getDeviceManagementApiClient as apiClient,
  getBranchSearchApiClient as branchapiClient,
} from "../api-helper/apiHelper";
import { DeviceDataProps } from "../../components/types/DeviceModal";
import {
  ERROR_MESSGAES,
  DEVICE_REGISTERED_SUCCESSFULLY,
  DEVICE_UPDATED_SUCCESSFULLY,
  DEVICE_ID_MAX_CHARACTERS,
  DEVICE_NAME_MAX_CHARACTERS,
  NOTES_MAX_CHARACTERS,
  BRANCH_FINDER_ERRORS,
} from "../../constants";
import { useAppVersion } from "./useAppVersion";
import {
  Device2StateEnum as DeviceStateEnum,
  Device2DeactivationCodeEnum as DeviceDeactivationCodeEnum,
} from "../../openapi/device-api/api";

const {
  DEVICE_ID_MAX_CHAR_ERROR,
  DEVICE_ID_MIN_CHAR_ERROR,
  DEVICE_ID_ALREADY_EXISTS_ERROR,
  DEVICE_NAME_MAX_CHAR_ERROR,
  DEVICE_NAME_MIN_CHAR_ERROR,
  BRANCH_ID_ERROR,
  BRANCH_ID_NOT_FOUND_ERROR,
  BRANCH_ID_CHANGE_ERROR,
  DEVICE_TYPE_ERROR,
  DEVICE_DEACTIVATION_CODE_ERROR,
  NODE_ID_MAX_CHAR_ERROR,
  NODE_ID_ERROR,
  NODE_ID_ALREADY_USED_ERROR,
  NOTES_MAX_CHAR_ERROR,
} = ERROR_MESSGAES;

export const useAddEditDevice = ({ pageType }) => {
  const [branchId, setBranchId] = useState<string>("");
  const [branchValid, setBranchValid] = useState<boolean>(true);
  const [branchName, setBranchName] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [deviceName, setDeviceName] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [deviceId, setDeviceId] = useState<string>("");
  const [nodeId, setNodeId] = useState<string>("");
  const [deviceState, setDeviceState] = useState<string>("");
  const [orgDeviceState, setOrgDeviceState] = useState<string>("");
  const [chooseDeactivationCode, setChooseDeactivationCode] = useState<boolean>(false);
  const [deactivationCode, setDeactivationCode] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const { errorFormattor } = useErrorMessage();
  const [branchOrgUnitCode, setBranchOrgUnitCode] = useState<string | undefined>("");
  const [branchOrgUnitCodeVersion] = useState<string | undefined>("");
  const [branchAddress, setBranchAddress] = useState<string>("");
  const [branchPostcode, setBranchPostcode] = useState<string>("");

  const [locationTypeCode, setLocationTypeCode] = useState<number | null>(null);
  const [locationTypeName, setLocationTypeName] = useState<string>("");
  const [tradingOpenStatus, setTradingOpenStatus] = useState<string>("");
  const [tradingSubStatusCode, setTradingSubStatusCode] = useState<string>("");
  const [tradingSubStatusName, setTradingSubStatusName] = useState<string>("");
  const [isDirtyState, setIsDirtyState] = useState<boolean>(false);
  const [isShowingStateWarning, setShowingStateWarning] = useState<boolean>(false);
  const toggleDeviceStateWarning = () => setShowingStateWarning(!isShowingStateWarning);

  const { isShowing, toggle } = useModal();
  const navigate = useNavigate();
  const location = window.location;
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const editDataFetched = useRef<boolean>(false);
  const params = useParams();
  const { version } = useAppVersion();

  const addUpdateDeviceParams = {
    id: deviceId,
    name: deviceName,
    branch_address: branchAddress,
    branch_id: branchId,
    branch_name: branchName,
    branch_org_unit_code_version: branchOrgUnitCodeVersion,
    branch_org_unit_code: branchOrgUnitCode,
    branch_postcode: branchPostcode,
    location_type_code: locationTypeCode,
    location_type_name: locationTypeName,
    trading_open_status: tradingOpenStatus,
    trading_sub_status_code: tradingSubStatusCode,
    trading_sub_status_name: tradingSubStatusName,
    node_id: nodeId,
    type: type,
    state: DeviceStateEnum.Active,
    deactivation_code: DeviceDeactivationCodeEnum.FaultyDevice,
    notes: notes,
  };
  const putApiOptions = {
    v1: addUpdateDeviceParams,
    v2: [
      deviceId,
      {
        branch_id: branchId,
        id: deviceId,
        name: deviceName,
        node_id: nodeId,
        type: type,
        notes: notes,
        state: deviceState,
        deactivation_code:
          deviceState === DeviceStateEnum.Active ? DeviceDeactivationCodeEnum.FaultyDevice : deactivationCode,
      },
    ],
  };

  const [
    { data: deviceApiResponse, status: deviceApiStatus, error: deviceApiError, statusCode: deviceApiStatusCode },
    fetchDevice,
    refreshDevice,
  ] = useApi(apiClient, pageType === "ADD" ? addUpdateDeviceParams : putApiOptions[`${version}`], undefined, "");
  const [
    { data: deviceDetailApiResponse, error: deviceDetailApiError, statusCode: deviceDetailApiStatus },
    getDeviceList,
  ] = useApi(apiClient, [params.deviceID], undefined, "GET");

  const [{ data: branchData, error: branchError, statusCode: status }, getBranchData, branchRefresh] = useApi<
    typeof branchapiClient
  >(branchapiClient, ["Device_Manager", branchId], undefined, "GET");

  // Handle device type selectbox changes
  const onTypeChange = (selected: any, event: any): void => {
    if (event.action !== "clear") {
      setType(selected.value);
      removeValidationErrors([DEVICE_TYPE_ERROR]);
    } else {
      setType(null);
    }
  };

  // Handle device state selectbox changes
  const onDeviceStateChange = (selected: any, event: any): void => {
    if (event.action !== "clear") {
      setDeviceState(selected.value);

      if (selected.value === DeviceStateEnum.Active) {
        setDeactivationCode(DeviceDeactivationCodeEnum.FaultyDevice);
      }
    } else {
      setDeviceState(deviceDetailApiResponse.state || DeviceStateEnum.Active);
      setDeactivationCode(null);
    }

    setChooseDeactivationCode(false);
    setIsDirtyState(false);
    if (deviceDetailApiResponse.state !== selected.value) {
      setIsDirtyState(true);
    }
  };

  // Handle reason code selectbox changes
  const onDeactivationCodeChange = (selected: any, event: any): void => {
    if (event.action !== "clear") {
      setDeactivationCode(selected.value);
      setChooseDeactivationCode(true);
      removeValidationErrors([DEVICE_DEACTIVATION_CODE_ERROR]);
    } else {
      setDeactivationCode(null);
    }
  };

  useEffect(() => {
    if (params.deviceID) {
      getDeviceList(`${version}GetDevice`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const onBranchIdChange = (branchId: string) => {
    setBranchId(branchId.toUpperCase());
    setBranchValid(false);
  };

  const handleMaxCharValidation = (inputValue: string, errors: string[], maxLength: number) => {
    if (inputValue.length > maxLength && !validationErrors.includes(errors[0])) {
      validationErrors.push(errors[0]);
      setValidationErrors(validationErrors);
    } else if (inputValue.length <= maxLength && validationErrors.includes(errors[0])) {
      removeValidationErrors(errors);
    }
  };

  // Handle input for device name change
  const onDeviceNameChange = (event: any) => {
    const deviceNameValue = event.target.value as string;
    setDeviceName(deviceNameValue);
    handleMaxCharValidation(
      deviceNameValue,
      [DEVICE_NAME_MAX_CHAR_ERROR, DEVICE_NAME_MIN_CHAR_ERROR],
      DEVICE_NAME_MAX_CHARACTERS,
    );
  };

  const onNotesChange = (event: any) => {
    const notesValue = event.target.value as string;
    setNotes(notesValue);
    handleMaxCharValidation(notesValue, [NOTES_MAX_CHAR_ERROR], NOTES_MAX_CHARACTERS);
  };

  // Handle input for deviceId change
  const onDeviceIdChange = (event: any) => {
    const deviceIdValue = event.target.value as string;

    setDeviceId(deviceIdValue);
    handleMaxCharValidation(
      deviceIdValue,
      [DEVICE_ID_MAX_CHAR_ERROR, DEVICE_ID_MIN_CHAR_ERROR],
      DEVICE_ID_MAX_CHARACTERS,
    );
  };

  // Handle input for nodeId change
  const onNodeIdChange = (event: any) => {
    const nodeIdValue = event.target.value as string;
    setNodeId(nodeIdValue);
    handleMaxCharValidation(nodeIdValue, [NODE_ID_MAX_CHAR_ERROR, NODE_ID_ERROR], 2);
  };

  // Handle cancel
  const cancel = () => {
    if (isDirty) {
      toggle();
      navigate(-1);
    } else {
      navigate(`/${version}/devices`);
    }
  };

  const handleUpdateButton = () => {
    if (isDirtyState) {
      toggleDeviceStateWarning();
    } else {
      handleEditDevice();
    }
  };

  // Submit device - branch optional
  const handleAddDevice = async () => {
    setValidationErrors([]);
    const errors = validator.validate({
      branch_address: branchAddress,
      branch_id: branchId,
      branch_name: branchName,
      branch_org_unit_code_version: branchOrgUnitCodeVersion,
      branch_org_unit_code: branchOrgUnitCode,
      branch_postcode: branchPostcode,
      location_type_code: locationTypeCode,
      location_type_name: locationTypeName,
      trading_open_status: tradingOpenStatus,
      trading_sub_status_code: tradingSubStatusCode,
      trading_sub_status_name: tradingSubStatusName,
      id: deviceId,
      name: deviceName,
      node_id: nodeId,
      type: type,
      state: DeviceStateEnum.Active,
      deactivation_code: DeviceDeactivationCodeEnum.FaultyDevice,
      notes: notes,
      branchValid,
    });

    if (errors && errors.length === 0) {
      deviceApiResponse || deviceApiError ? refreshDevice() : fetchDevice(`${version}PostDevice`);
      await deviceApiResponse;
    } else {
      setValidationErrors(errors);
    }
  };

  const onShowActivationModal = async () => {
    if (isDirtyState) {
      setShowingStateWarning(true);
      navigate(-1);
    } else {
      await handleEditDevice();
    }
  };

  const handleEditDevice = async () => {
    setValidationErrors([]);

    const errors = validator.validate({
      branch_address: branchAddress,
      branch_id: branchId,
      branch_name: branchName,
      branch_org_unit_code_version: branchOrgUnitCodeVersion,
      branch_org_unit_code: branchOrgUnitCode,
      branch_postcode: branchPostcode,
      location_type_code: locationTypeCode,
      location_type_name: locationTypeName,
      trading_open_status: tradingOpenStatus,
      trading_sub_status_code: tradingSubStatusCode,
      trading_sub_status_name: tradingSubStatusName,
      id: deviceId,
      name: deviceName,
      node_id: nodeId,
      type: type,
      state: deviceState,
      deactivation_code: deactivationCode,
      notes: notes,
      orgDeviceState: orgDeviceState,
      chooseDeactivationCode: chooseDeactivationCode,
      branchValid,
    });

    if (errors && errors.length === 0) {
      deviceApiResponse || deviceApiError ? refreshDevice() : fetchDevice(`${version}PutDevice`);
      await deviceApiResponse;
    } else {
      setValidationErrors(errors);
    }
  };

  useLayoutEffect(() => {
    if (deviceApiResponse !== typeof undefined && deviceApiStatusCode === 201) {
      setIsDirty(false);
      setTimeout(() => {
        navigate(`/${version}/devices`, { replace: true });
      }, 1000);
      toast.info(DEVICE_REGISTERED_SUCCESSFULLY);
    } else if (deviceApiResponse !== typeof undefined && deviceApiStatusCode === 200) {
      toast.success(DEVICE_UPDATED_SUCCESSFULLY, { theme: "light" });
      setIsDirty(false);
      setTimeout(() => {
        navigate(`/${version}/devices`, { replace: true });
      }, 1000);
    } else if (deviceApiStatusCode === 409 && deviceApiStatus === "error") {
      setValidationErrors([NODE_ID_ALREADY_USED_ERROR]);
      return;
    } else if (deviceApiError && deviceApiStatus === "error") {
      if (deviceApiError.includes("UsernameExistsException")) {
        setValidationErrors([DEVICE_ID_ALREADY_EXISTS_ERROR]);
        toast.error(DEVICE_ID_ALREADY_EXISTS_ERROR);
      } else {
        setValidationErrors([deviceApiError as string]);
        errorFormattor(deviceApiError).forEach((i) => {
          toast.error(i);
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceApiResponse, deviceApiError, deviceApiStatus, deviceApiStatusCode]);

  useLayoutEffect(() => {
    if (deviceDetailApiStatus === 200) {
      setBranchAddress(deviceDetailApiResponse.branch_address);
      setDeviceId(deviceDetailApiResponse.id);
      setDeviceName(deviceDetailApiResponse.name);
      setType(deviceDetailApiResponse.type);
      setNodeId(deviceDetailApiResponse.node_id);
      setBranchId(deviceDetailApiResponse.branch_id);
      setBranchName(deviceDetailApiResponse.branch_name);
      setBranchAddress(deviceDetailApiResponse.branch_address);
      setBranchPostcode(deviceDetailApiResponse.branch_postcode);
      setBranchOrgUnitCode("" + deviceDetailApiResponse.branch_org_unit_code);
      setLocationTypeCode(deviceDetailApiResponse.location_type_code);
      setLocationTypeName(deviceDetailApiResponse.location_type_name);
      setTradingOpenStatus(deviceDetailApiResponse.trading_open_status);
      setTradingSubStatusCode(deviceDetailApiResponse.trading_sub_status_code);
      setTradingSubStatusName(deviceDetailApiResponse.trading_sub_status_name);
      setDeviceState(deviceDetailApiResponse.state || DeviceStateEnum.Active);
      setDeactivationCode(deviceDetailApiResponse.deactivation_code);
      setOrgDeviceState(deviceDetailApiResponse.state || DeviceStateEnum.Active);
      setNotes(deviceDetailApiResponse.notes);
      setTimeout(() => {
        editDataFetched.current = true;
      }, 500);
    } else {
      toast.error(deviceDetailApiError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceDetailApiResponse, deviceDetailApiStatus, deviceDetailApiError]);

  useLayoutEffect(() => {
    if (status === 200) {
      removeValidationErrors([BRANCH_ID_CHANGE_ERROR, BRANCH_ID_ERROR, BRANCH_ID_NOT_FOUND_ERROR]);
      if (branchData.Data && branchData.Data.length > 0) {
        let data = branchData.Data[0];
        setBranchId(data.BranchId);
        setBranchName(data.BranchName);
        setBranchAddress(data.BranchAddress);
        setBranchPostcode(data.BranchPostcode);
        setBranchOrgUnitCode("" + data.BranchOrgUnitCode);
        setBranchValid(true);
        setLocationTypeCode(data.LocationTypeCode);
        setLocationTypeName(data.LocationTypeName);
        setTradingOpenStatus(data.TradingOpenStatus);
        setTradingSubStatusCode(data.TradingSubStatusCode);
        setTradingSubStatusName(data.TradingSubStatusName);
      } else {
        resetBranchData();
        validationErrors.push(BRANCH_ID_NOT_FOUND_ERROR);
      }
    } else {
      toast.error(BRANCH_FINDER_ERRORS[`ERROR_${branchError}`] ?? branchError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchData, status, branchError]);

  const removeValidationErrors = (errors: string[]) => {
    errors.forEach((error) => {
      if (validationErrors.includes(error)) {
        validationErrors.splice(validationErrors.indexOf(error), 1);
      }
    });
    setValidationErrors(validationErrors);
  };

  const resetBranchData = () => {
    setBranchName("");
    setBranchAddress("");
    setBranchPostcode("");
    setBranchOrgUnitCode("");
    setLocationTypeCode(null);
    setLocationTypeName("");
    setTradingOpenStatus("");
    setTradingSubStatusCode("");
    setTradingSubStatusName("");
  };

  const confirm = (confirmation: string) => {
    toggle();
    if (confirmation === ModelConfirmation.Yes) {
      setIsDirty(false);
      navigate(`/${version}/devices`);
    }
  };

  const confirmStateChanges = (confirmation: string) => {
    toggleDeviceStateWarning();
    if (confirmation === ModelConfirmation.Yes) {
      setShowingStateWarning(false);

      handleEditDevice();
    } else {
      toggleDeviceStateWarning();
    }
  };

  useEffect(() => {
    if (!isShowing && isDirty) {
      window.history.pushState(null, null, window.location.pathname + "#/device");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowing]);

  const onBackButtonEvent = (event: PopStateEvent) => {
    event.preventDefault();
    if (isDirty) {
      toggle();
    } else {
      navigate(`/${version}/devices`);
    }
  };

  useEffect(() => {
    if (location.hash.includes("#/device")) {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDirty) {
      window.history.pushState(null, null, window.location.pathname + "#/device");
      window.addEventListener("popstate", onBackButtonEvent);
      return () => {
        window.removeEventListener("popstate", onBackButtonEvent);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  const searchBranch = async () => {
    branchData || branchError ? branchRefresh() : getBranchData("apiconsumerBranchDetailsV01Get");
    await branchData;
  };

  useDidMountEffect(() => {
    //Do not set dirty, if data is filled from edit api
    if (pageType === "EDIT" && !editDataFetched.current) {
      return;
    }
    setIsDirty(true);
  }, [
    branchAddress,
    branchId,
    branchName,
    branchOrgUnitCodeVersion,
    branchOrgUnitCode,
    branchPostcode,
    locationTypeCode,
    locationTypeName,
    tradingOpenStatus,
    tradingSubStatusCode,
    tradingSubStatusName,
    deviceId,
    deviceName,
    nodeId,
    type,
    deviceState,
    deactivationCode,
    notes,
  ]);

  const deviceData: DeviceDataProps = {
    modalStatus: pageType,
    deviceId: deviceId,
    deviceName: deviceName,
    notes: notes,
    nodeId: nodeId,
    branchId: branchId,
    branchName: branchName,
    branchAddress: branchAddress,
    branchPostcode: branchPostcode,
    branchOrgUnitCode: branchOrgUnitCode,
    locationTypeCode: locationTypeCode,
    locationTypeName: locationTypeName,
    tradingOpenStatus: tradingOpenStatus,
    tradingSubStatusCode: tradingSubStatusCode,
    tradingSubStatusName: tradingSubStatusName,
    deviceState: deviceState,
    deactivationCode:
      orgDeviceState === DeviceStateEnum.Active || orgDeviceState === ""
        ? ""
        : deactivationCode || deviceDetailApiResponse?.deactivation_code,
    isShowing: isShowing,
    isShowingStateWarning: isShowingStateWarning,
    type: type,
    toggle: toggle,
    cancel: cancel,
    confirm: confirm,
    toggleDeviceStateWarning: toggleDeviceStateWarning,
    confirmStateChanges: confirmStateChanges,
    searchBranch: searchBranch,
  };

  return {
    onBranchIdChange,
    onDeviceIdChange,
    onDeviceNameChange,
    onDeviceStateChange,
    onDeactivationCodeChange,
    onShowActivationModal,
    onNotesChange,
    onTypeChange,
    onNodeIdChange,
    setBranchId,
    setBranchName,
    setBranchAddress,
    setBranchPostcode,
    setBranchOrgUnitCode,
    setLocationTypeCode,
    setLocationTypeName,
    setTradingOpenStatus,
    setTradingSubStatusCode,
    setTradingSubStatusName,
    setDeviceState,
    setDeactivationCode,
    handleAddDevice,
    handleEditDevice,
    handleUpdateButton,
    deviceData,
    validationErrors,
    deviceDetailApiResponse,
  };
};
