import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useApi } from "./useApi";
import { AppContext } from "../../context/store";
import { ActionType } from "../../context/reducer";
import { getUserManagementApiClient as apiClient } from "../../lib/api-helper/apiHelper";
import { Auth } from "aws-amplify";
import { ROWS_PER_PAGE_V1, ROWS_PER_PAGE_V2 } from "../../constants";
import moment from "moment";
import { useAppVersion } from "./useAppVersion";
import { useSearchParams } from "react-router-dom";

const userEntity = {
  v1: "users",
  v2: "items",
};

export const useUsers = () => {
  const { state, dispatch } = useContext(AppContext);
  const [searchText, setSearchText] = useState<string>("");
  const [offset, setOffset] = useState<string>("");
  const [limit, setLimit] = useState<number>();
  const [totalRowsUsers, setTotalRowsUsers] = useState<number>();
  const [sortBy, setSortBy] = useState<string>(undefined);
  const [sortDirection, setSortDirection] = useState<string>(undefined);
  const [currentState, setCurrentState] = useState<string>("");
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState<number>(Number(searchParams.get("page")) || 1);

  const apiOptions = {
    v1: [limit ?? ROWS_PER_PAGE_V1, offset, searchText],
    v2: [limit ?? ROWS_PER_PAGE_V2, page, searchText, sortBy, sortDirection],
  };
  const { version } = useAppVersion();

  const [{ data: usersData }, getUsers, refresh] = useApi(apiClient, apiOptions[`${version}`], currentState, "GET");
  const [{ data: exportedData, statusCode, error }, getExportData, refreshExport] = useApi(
    apiClient,
    ["users"],
    undefined,
    "GET",
  );

  useEffect(() => {
    async function fetchCurrentUserRole() {
      const idToken = (await Auth.currentSession()).getIdToken();
      dispatch({
        type: ActionType.SET_USER_ROLE,
        payload: idToken.payload["custom:role"],
      });
    }
    fetchCurrentUserRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (version === "v2" && searchParams) {
      if (searchParams.get("limit")) {
        setLimit(Number(searchParams.get("limit")));
        handleRowsPerPageChange(Number(searchParams.get("limit")), page);
      }
      if (searchParams.get("page")) {
        handlePageChange(Number(searchParams.get("page")));
      }
      if (searchParams.get("q")) {
        setSearchText(String(searchParams.get("q")));
      }
      if (searchParams.get("sortBy")) {
        setSortBy(String(searchParams.get("sortBy")));
      }
      if (searchParams.get("sortDirection")) {
        setSortDirection(String(searchParams.get("sortDirection")));
      }
    }
    if (version === "v1" && searchParams) {
      if (searchParams.get("q")) {
        setSearchText(String(searchParams.get("q")));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.users !== typeof undefined) {
      setCurrentState(state.users);
    }

    if (state.nextPage.page !== undefined) {
      setOffset(state.nextPage.page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.users, state.nextPage]);

  useEffect(() => {
    async function loadData() {
      const usersResponse = usersData;
      dispatch({ type: ActionType.SET_TOTAL_RECORDS, payload: usersResponse.total });
      if (usersResponse.offset && state.nextPage[page] === undefined) {
        setOffset(usersResponse.offset);
        dispatch({ type: ActionType.NEXT_PAGE, payload: usersResponse.offset });
      }
      const entity = `${userEntity[version]}`;
      if (state.users.length !== 0) {
        const currentUsers = state.users[`${entity}`];
        const nextUsers = usersResponse[`${entity}`];
        usersResponse.users = currentUsers.concat(nextUsers);
      }
      setTotalRowsUsers(usersResponse.total);
      dispatch({ type: ActionType.SET_USERS, payload: usersResponse });
    }

    usersData && loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersData]);

  useEffect(() => {
    async function loadNextData() {
      updatePage(limit);
    }
    offset && loadNextData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  useEffect(() => {
    // trigger search on browser back button click, update page results with search data
    if (searchParams.get("q")) {
      setSearchText(searchParams.get("q"));
    } else {
      setSearchText("");
      setOffset("");
    }
    dispatch({ type: ActionType.SET_USERS, payload: [] });
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, searchParams]);

  const refreshUsers = () => {
    !usersData ? getUsers(`${version}GetUsers`) : refresh();
  };

  const retrieveUsers = () => {
    getUsers(`${version}GetUsers`);
  };

  const newPage = (page, limit) => {
    setPage(page);
    let currentPage = state.nextPage[page - 1].page;
    setOffset(currentPage);
    setLimit(limit);

    usersData ? refresh() : getUsers(`${version}GetUsers`);
  };

  const updatePage = (limit) => {
    setLimit(limit);
    usersData ? refresh() : getUsers(`${version}GetUsers`);
  };

  const searchUser = (searchString: string) => {
    setSearchText(searchString);
    if (searchString) {
      if (searchParams.get("page")) {
        searchParams.set("page", "1");
      }
      searchParams.set("q", searchString);
      setSearchParams(searchParams);
    }
  };

  const exportUserData = async () => {
    exportedData || error ? refreshExport() : getExportData("v2UsersExport");
  };

  useLayoutEffect(() => {
    if (statusCode === 200) {
      var csvData = new Blob([exportedData], { type: "text/csv" });
      var csvUrl = URL.createObjectURL(csvData);
      const link = document.createElement("a");
      link.href = csvUrl;
      link.setAttribute("download", `Users_${moment(new Date()).format("YYYYMMDDHHmmss")}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportedData, statusCode]);

  const handlePageChange = (page) => {
    setPage(page);
    searchParams.set("page", page);
    setSearchParams(searchParams);
    getUsers("v2GetUsers");
    refresh();
  };

  const handleRowsPerPageChange = (newPerPage, page) => {
    setLimit(newPerPage);
    setPage(page);
    searchParams.set("limit", newPerPage);
    searchParams.set("page", page);
    setSearchParams(searchParams);
    getUsers("v2GetUsers");
    refresh();
  };

  const handleSorting = (column, sortDirection) => {
    setSortBy(column.sortField);
    setSortDirection(sortDirection);
    searchParams.set("sortBy", column.sortField);
    searchParams.set("sortDirection", sortDirection);
    setSearchParams({ sortBy: column.sortField, sortDirection: sortDirection });
    getUsers("v2GetUsers");
    refresh();
  };

  return {
    refreshUsers,
    retrieveUsers,
    newPage,
    updatePage,
    searchUser,
    exportUserData,
    exportedData,
    totalRowsUsers,
    handlePageChange,
    handleRowsPerPageChange,
    handleSorting,
  };
};
