import { FC, useEffect, useRef } from "react";
import Select from "react-select";
import { Check, X } from "heroicons-react";
import Modal from "../Modal";
import { V2UseAddEditDevice } from "../../lib/hooks/v2/useAddEditDevice";
import {
  BRANCH_ID_DIGIT_REGEXP,
  BRANCH_ID_REGEXP,
  DEVICE_TYPE_OPTIONS,
  DEVICE_STATE_OPTIONS,
  DEVICE_DEACTIVATION_CODE,
  ERROR_MESSGAES,
} from "../../constants";
import { DeviceAddEditModalProps } from "../types/DeviceModal";
import { customStyles } from "../styles/deviceModalStyles";
import DeviceFieldComponent from "../DeviceFieldComp";
import DeviceFieldErrorComponent from "../DeviceFieldErrorComp";
import { Device2StateEnum } from "../../openapi/device-api/api";
import { transformNodeIds } from "../helpers";

const V2DeviceAddEditModal: FC<DeviceAddEditModalProps> = ({ pageType }): JSX.Element => {
  const {
    handleAddDevice,
    handleUpdateButton,
    handleCancelButton,
    onDeviceDataChange,
    searchBranch,
    deviceData,
    modalData,
    validationErrors,
  } = V2UseAddEditDevice({
    pageType,
  });

  const {
    modalStatus,
    id,
    name,
    notes,
    nodeId,
    orgNodeId,
    orgBranchId,
    nodeIds,
    branchId,
    branchName,
    branchAddress,
    branchPostcode,
    branchOrgUnitCode,
    locationTypeCode,
    locationTypeName,
    tradingOpenStatus,
    tradingSubStatusCode,
    tradingSubStatusName,
    type,
    deviceState,
    deactivationCode,
  } = deviceData;

  const {
    isShowCancelModal,
    isShowStateModal,
    toggleCancelWarning,
    confirmCancel,
    toggleStateWarning,
    confirmStateChanges,
  } = modalData;

  const state = useRef<string>(deviceState);
  const defaultNodeId = () => {
    if (orgBranchId !== branchId && !nodeId) {
      return "";
    } else if (orgBranchId === branchId) {
      return nodeId;
    }

    return orgBranchId === branchId ? orgNodeId : nodeId;
  };

  let transformedNodeIds = [];
  if (nodeIds) {
    transformedNodeIds = transformNodeIds(nodeIds);
  }

  useEffect(() => {
    if (deviceState && !state.current) {
      state.current = deviceState;
    }
  }, [deviceState]);

  const isBranchValid = (): boolean => {
    return branchId && (BRANCH_ID_REGEXP.test(branchId) || BRANCH_ID_DIGIT_REGEXP.test(branchId));
  };

  const locationDetail = branchAddress ? branchAddress.split(",") : [];
  const [branchAddress1, branchAddress2, branchAddress3, branchAddress4, branchAddress5] = locationDetail;

  const fieldClassName = "edit-data-input sm:text-sm disabled";
  const data = [
    {
      title: "Branch Name",
      id: "branchName",
      value: branchName,
    },
    {
      title: "Branch Address Line1",
      id: "branchAddress1",
      value: branchAddress1 || "",
    },
    {
      title: "Branch Address Line2",
      id: "branchAddress2",
      value: branchAddress2 || "",
    },
    {
      title: "Branch Address Line3",
      id: "branchAddress3",
      value: branchAddress3 || "",
    },
    {
      title: "Branch Address Line4",
      id: "branchAddress4",
      value: branchAddress4 || "",
    },
    {
      title: "Branch Address Line5",
      id: "branchAddress5",
      value: branchAddress5 || "",
    },
    {
      title: "Branch Postcode",
      id: "branchPostcode",
      value: branchPostcode,
    },
    {
      title: "Branch Org Unit Code",
      id: "branchOrgUnitCode",
      value: branchOrgUnitCode,
    },
    {
      title: "Location Type",
      id: "locationTypeCode",
      value: locationTypeCode?.toString() ?? "",
    },
    {
      title: "Location Type Name",
      id: "locationTypeName",
      value: locationTypeName,
    },
    {
      title: "Trading SubStatus",
      id: "tradingSubStatusName",
      value: tradingSubStatusName,
    },
    {
      title: "Trading Substatus Code",
      id: "tradingSubStatusCode",
      value: tradingSubStatusCode?.toString(),
    },
    {
      title: "Trading Open Status",
      id: "tradingOpenStatus",
      value: tradingOpenStatus,
    },
  ];

  return (
    <div className="">
      <dl className="flex-grow p-8 divide-y divide-gray-50">
        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">
            Device ID
            {modalStatus === "ADD" && <span className="text-pol-red">*</span>}
          </dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <DeviceFieldComponent
                disabled={modalStatus === "EDIT"}
                id="deviceId"
                value={id}
                onChange={(e) => onDeviceDataChange("id", e.target.value)}
                className={`edit-data-input sm:text-sm ${modalStatus === "EDIT" ? "disabled" : ""}`}
              />
              <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <DeviceFieldErrorComponent
                  message2={ERROR_MESSGAES.DEVICE_ID_MAX_CHAR_ERROR}
                  message1={ERROR_MESSGAES.DEVICE_ID_MIN_CHAR_ERROR}
                  validationErrors={validationErrors}
                />
                <DeviceFieldErrorComponent
                  message1={ERROR_MESSGAES.DEVICE_ID_SPACE_CHAR_ERROR}
                  validationErrors={validationErrors}
                />
              </div>
            </span>
          </dd>
        </div>
        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">Device Name {<span className="text-pol-red">*</span>}</dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <DeviceFieldComponent
                id="deviceName"
                value={name}
                onChange={(e) => onDeviceDataChange("name", e.target.value)}
                className="edit-data-input sm:text-sm disabled:cursor-pointer disabled:"
              />
              <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <DeviceFieldErrorComponent
                  message2={ERROR_MESSGAES.DEVICE_NAME_MAX_CHAR_ERROR}
                  message1={ERROR_MESSGAES.DEVICE_NAME_MIN_CHAR_ERROR}
                  validationErrors={validationErrors}
                />
              </div>
            </span>
          </dd>
        </div>
        <div className="edit-data-row sm:pt-5">
          <dt className="text-sm font-medium text-gray-700">
            Device Type {modalStatus === "ADD" && <span className="text-pol-red">*</span>}
          </dt>
          <dd className="flex mt-1 overflow-visible text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <Select
                isDisabled={modalStatus === "EDIT"}
                onChange={(e) => onDeviceDataChange("type", e.value)}
                options={DEVICE_TYPE_OPTIONS}
                value={DEVICE_TYPE_OPTIONS.find((item) => item.value === type)}
                isSearchable
                maxMenuHeight={200}
                styles={customStyles}
                className={`flex-1 capitalize`}
              />
              <DeviceFieldErrorComponent
                message1={ERROR_MESSGAES.DEVICE_TYPE_ERROR}
                validationErrors={validationErrors}
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">
            Branch ID <span className="text-pol-red">*</span>
          </dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <DeviceFieldComponent
                id="branchId"
                required
                value={branchId}
                onChange={(e) => onDeviceDataChange("branchId", e.target.value)}
                className={"edit-data-input sm:text-sm"}
              />
              <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <DeviceFieldErrorComponent
                  message2={ERROR_MESSGAES.BRANCH_ID_ERROR}
                  message1={ERROR_MESSGAES.BRANCH_ID_NOT_FOUND_ERROR}
                  validationErrors={validationErrors}
                />
                <DeviceFieldErrorComponent
                  message1={ERROR_MESSGAES.BRANCH_ID_CHANGE_ERROR}
                  validationErrors={validationErrors}
                />
              </div>
            </span>
            <button
              type="button"
              disabled={!isBranchValid()}
              className={
                isBranchValid()
                  ? "inline-flex items-center btn btn-clear ml-4"
                  : "inline-flex items-center btn btn-disable ml-4"
              }
              onClick={(): Promise<void> => searchBranch()}
            >
              Find Branch
            </button>
          </dd>
        </div>

        <div
          className="edit-data-row sm:pt-5"
          data-testid={`nodeId-${deviceState === Device2StateEnum.Inactive ? "disable" : "enabled"}`}
        >
          <dt className="text-sm font-medium text-gray-700">
            Node ID <span className="text-pol-red">*</span>
          </dt>
          <dd className="flex mt-1 overflow-visible text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <Select
                onChange={(e) => onDeviceDataChange("nodeId", e.value)}
                options={transformedNodeIds}
                value={
                  !defaultNodeId() ? null : transformedNodeIds.find((element) => element.value === defaultNodeId())
                }
                isSearchable
                data-testid="testId-nodeId"
                maxMenuHeight={200}
                styles={customStyles}
                className={`flex-1 capitalize`}
                isDisabled={deviceState === Device2StateEnum.Inactive}
              />
              <div className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <DeviceFieldErrorComponent
                  message2={ERROR_MESSGAES.NODE_ID_MAX_CHAR_ERROR}
                  message1={ERROR_MESSGAES.NODE_ID_ERROR}
                  validationErrors={validationErrors}
                />
                <DeviceFieldErrorComponent
                  message1={ERROR_MESSGAES.NODE_ID_ALREADY_USED_ERROR}
                  validationErrors={validationErrors}
                />
              </div>
            </span>
          </dd>
        </div>

        {data?.map((item) => (
          <DeviceFieldComponent
            title={item.title}
            key={item.id}
            id={item.id}
            required
            disabled
            value={item.value}
            className={fieldClassName}
          />
        ))}

        <div className="edit-data-row sm:pt-5">
          <dt className="text-sm font-medium text-gray-700">Activate/Deactivate</dt>
          <dd className="flex mt-1 overflow-visible text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <Select
                onChange={(e) => onDeviceDataChange("state", e.value)}
                options={DEVICE_STATE_OPTIONS}
                defaultValue={DEVICE_STATE_OPTIONS[0]}
                value={DEVICE_STATE_OPTIONS.find((item) => item.value === (deviceState || Device2StateEnum.Active))}
                isSearchable
                maxMenuHeight={200}
                styles={customStyles}
                className={`flex-1 capitalize`}
                isDisabled={modalStatus === "ADD"}
                id="state"
              />
            </span>
          </dd>
        </div>
        {deviceState === Device2StateEnum.Inactive && (
          <div className="edit-data-row sm:pt-5">
            <dt className="text-sm font-medium text-gray-700">
              Reason Code <span className="text-pol-red">*</span>
            </dt>
            <dd className="flex mt-1 overflow-visible text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">
                <Select
                  onChange={(e) => onDeviceDataChange("deactivationCode", e.value)}
                  options={DEVICE_DEACTIVATION_CODE}
                  value={DEVICE_DEACTIVATION_CODE.find((item) => item.value === deactivationCode)}
                  isSearchable
                  maxMenuHeight={200}
                  styles={customStyles}
                  className={`flex-1`}
                />
                <DeviceFieldErrorComponent
                  message1={ERROR_MESSGAES.DEVICE_DEACTIVATION_CODE_ERROR}
                  validationErrors={validationErrors}
                />
              </span>
            </dd>
          </div>
        )}

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">Device notes</dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <textarea
                value={notes}
                onChange={(e) => onDeviceDataChange("notes", e.target.value)}
                placeholder="Device notes"
                data-testid="notes"
                name="notes"
                rows={4}
                id="notes"
                className="edit-data-input sm:text-sm disabled:cursor-pointer disabled:"
              />
              <DeviceFieldErrorComponent
                message1={ERROR_MESSGAES.NOTES_MAX_CHAR_ERROR}
                validationErrors={validationErrors}
              />
            </span>
          </dd>
        </div>
      </dl>
      <div className="flex flex-row-reverse items-end flex-grow px-4 py-3 bg-white-50 sm:px-6">
        <button onClick={() => handleCancelButton()} type="button" className="ml-4 btn btn-cancel">
          <X className="inline" size={18} /> Cancel
        </button>

        {modalStatus === "ADD" ? (
          <button onClick={() => handleAddDevice()} type="button" className="ml-4 btn btn-success">
            <Check className="inline" size={18} /> Create Device
          </button>
        ) : (
          <button onClick={() => handleUpdateButton()} type="button" className="ml-4 btn btn-success">
            <Check className="inline" size={18} /> Update Device
          </button>
        )}
      </div>

      {isShowCancelModal && (
        <Modal
          isShowing
          hide={toggleCancelWarning}
          confirmation={confirmCancel}
          modalBody={<div>Are you sure you want to leave the page?</div>}
        />
      )}

      {isShowStateModal && (
        <Modal
          isShowing
          hide={toggleStateWarning}
          confirmation={confirmStateChanges}
          modalBody={
            <div>
              {state.current === Device2StateEnum.Active
                ? `Are you sure you want to deactivate this device?`
                : `Are you sure you want to activate this device?`}
            </div>
          }
        />
      )}
    </div>
  );
};

export default V2DeviceAddEditModal;
