import { Device as IDevice } from "../../lib/models";
import moment from "moment";
import { getDeviceManagementApiClient as deviceApiClient } from "../../lib/api-helper/apiHelper";
import useApi from "./useApi";
import { useLayoutEffect } from "react";
import { formatDate } from "../../components/helpers";

export const useExportDevices = () => {
  const [{ data: exportedData, statusCode, error }, getExportData, refreshExport] = useApi(
    deviceApiClient,
    ["devices"],
    undefined,
    "GET",
  );

  const exportedDevicesV2 = async () => {
    exportedData || error ? refreshExport() : getExportData("v2DevicesExport");
    await exportedData;
  };

  useLayoutEffect(() => {
    if (statusCode === 200) {
      var csvData = new Blob([exportedData], { type: "text/csv" });
      var csvUrl = URL.createObjectURL(csvData);
      const link = document.createElement("a");
      link.href = csvUrl;
      link.setAttribute("download", `Devices_${moment(new Date()).format("YYYYMMDDHHmmss")}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }, [exportedData, statusCode]);

  const exportedDevices = (devices: IDevice[]): IDevice[] => {
    const results = JSON.parse(JSON.stringify(devices));
    return results.map((device) => {
      const NOT_AVAILABLE = "N/A";
      const branchAddress = device.branch_address.split(",");
      const branch_address1 = branchAddress[0] ? branchAddress[0] : "";
      const branch_address2 = branchAddress[1] ? branchAddress[1] : "";
      const branch_address3 = branchAddress[2] ? branchAddress[2] : "";
      const branch_address4 = branchAddress[3] ? branchAddress[3] : "";
      const branch_address5 = branchAddress[4] ? branchAddress[4] : "";

      const modified_at = formatDate(device.modified_at, "MMM DD, YYYY HH:mm:ss");
      const created_at = formatDate(device.created_at, "MMM DD, YYYY HH:mm:ss");
      const modified_by = device.modified_by !== "" ? device.modified_by : NOT_AVAILABLE;

      return {
        ...device,
        modified_at,
        created_at,
        modified_by,
        branch_address1,
        branch_address2,
        branch_address3,
        branch_address4,
        branch_address5,
        location_type_code: "",
        location_type_name: "",
        trading_open_status: "",
        trading_sub_status_code: "",
        trading_sub_status_name: "",
      };
    });
  };

  const deviceHeaders = [
    { label: "Device ID", key: "id" },
    { label: "Device Name", key: "name" },
    { label: "Device Type", key: "type" },
    { label: "Node ID", key: "node_id" },
    { label: "Location FAD Code (Branch ID)", key: "branch_id" },
    { label: "Branch Name", key: "branch_name" },
    { label: "AddressLine1", key: "branch_address1" },
    { label: "AddressLine2", key: "branch_address2" },
    { label: "AddressLine3", key: "branch_address3" },
    { label: "AddressLine4", key: "branch_address4" },
    { label: "AddressLine5", key: "branch_address5" },
    { label: "PostCode", key: "branch_postcode" },
    { label: "Location Type", key: "location_type_code" },
    { label: "Location Type Name", key: "location_type_name" },
    { label: "Trading SubStatus", key: "trading_open_status" },
    { label: "Trading Substatus Code", key: "trading_sub_status_code" },
    { label: "Trading Open Status", key: "trading_sub_status_name" },
    { label: "Notes", key: "notes" },
    { label: "DateCreated", key: "created_at" },
    { label: "Last modified on", key: "modified_at" },
    { label: "Last modified by", key: "modified_by" },
  ];
  return {
    deviceHeaders,
    exportedDevices,
    exportedDevicesV2,
    exportedData,
  };
};
