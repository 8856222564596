import { useState, useLayoutEffect, useEffect, useRef } from "react";
import { useApi } from "./useApi";
import { getUserManagementApiClient as apiClient } from "../api-helper/apiHelper";
import { USER_ID, USER_EXISTS, INVALID_DOMAIN, USER_NOT_EXIST, ModelConfirmation } from "../../constants";
import useDidMountEffect from "../../lib/hooks/useDidMountEffect";
import validator from "../../validators/userValidator";
import { toast } from "react-toastify";
import { useModal } from "../../lib/hooks/useModal";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppVersion } from "./useAppVersion";
import { UpdateUserParams2DeactivationCodeEnum } from "../../openapi/device-api/api";

export const useAddEditUser = () => {
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [userState, setUserState] = useState<boolean>(true);
  const [deactivationCode, setDeactivationCode] = useState<string>("");
  const [isShowingStateWarning, setShowingStateWarning] = useState<boolean>(false);
  const toggleUserStateWarning = () => setShowingStateWarning(!isShowingStateWarning);
  const [isDirtyState, setIsDirtyState] = useState<boolean>(false);
  const [chooseDeactivationCode, setChooseDeactivationCode] = useState<boolean>(false);
  const [orgUserState, setOrgUserState] = useState<string>("");
  const [error, setError] = useState<string>();
  const [modalStatus, setModalStatus] = useState<string>("ADD");
  const [{ data: addUserResponse, status: addUserStatus, error: addUserError, statusCode }, addUser, refresh] = useApi(
    apiClient,
    {
      username: email,
      role: role,
    },
  );

  const { isShowing, toggle } = useModal();
  const navigate = useNavigate();
  const location = window.location;
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const editDataFetched = useRef<boolean>(false);
  const [searchParams] = useSearchParams();
  const { version } = useAppVersion();
  const id = searchParams.get(USER_ID);

  const putApiOptions = {
    v1: { username: email, updateUser: { role: role } },
    v2: {
      id: id,
      updateUser: {
        username: email,
        role: role,
        enable: userState,
        deactivation_code: deactivationCode,
      },
    },
  };

  const [
    { data: updateUserResponse, status: updateUserStatus, error: updateUserError, statusCode: updateUserStatusCode },
    updateUser,
    updateRefresh,
  ] = useApi(apiClient, putApiOptions[version], "", "PUT");

  const params = useParams();
  const [{ data: userData, error: userDataError, statusCode: userDataStatus }, getUserData] = useApi(
    apiClient,
    version === "v2" ? [params.id] : [encodeURI(params.username)],
    undefined,
    "GET",
  );

  useEffect(() => {
    if (params.username) {
      getUserData(`${version}GetUser`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  // Handle cancel
  const cancel = () => {
    if (isDirty) {
      toggle();
      navigate(-1);
    } else {
      navigate(`/${version}/users`);
    }
  };

  const handleAddUser = async () => {
    // Resetting the error
    setError(null);

    const errors = validator.validate({
      email: email,
      role: role,
      enabled: userState,
      deactivation_reason: deactivationCode,
      orgUserState: orgUserState,
      chooseDeactivationCode: chooseDeactivationCode,
    });
    if (errors?.length === 0) {
      addUserResponse || addUserError ? refresh() : addUser(`${version}PostUser`);
      await addUserResponse;
    } else {
      errors.forEach((error) => {
        toast.error(error);
      });
    }
  };

  const handleUpdateButton = () => {
    if (isDirtyState) {
      toggleUserStateWarning();
    } else {
      handleEditUser();
    }
  };

  const handleEditUser = async () => {
    const errors = validator.validate({
      email: email,
      role: role,
    });
    if (errors?.length === 0) {
      updateUserResponse || updateUserError ? updateRefresh() : updateUser(`${version}PutUser`);
      await updateUserResponse;
    } else {
      errors.forEach((error) => {
        toast.error(error);
      });
    }
  };

  useLayoutEffect(() => {
    if (userDataStatus === 200) {
      setEmail(userData.email);
      setRole(userData.role);
      setUserState(!userData.enabled ? userData.enabled : true);
      setDeactivationCode(userData.deactivation_code);
      setOrgUserState(userData.enabled || true);
      setTimeout(() => {
        editDataFetched.current = true;
      }, 500);
    } else if (userDataStatus === 404) {
      toast.error(USER_NOT_EXIST);
    } else {
      toast.error(userDataError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, userDataStatus, userDataError]);

  // identify if edit or add modal
  useLayoutEffect((): void => {
    params.username ? setModalStatus("EDIT") : setModalStatus("ADD");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.username]);

  useLayoutEffect(() => {
    if (addUserResponse !== typeof undefined && (statusCode === 200 || statusCode === 201)) {
      toast.info("User Added");
      navigate(`/${version}/users`, { replace: true });
    }

    if (addUserResponse !== typeof undefined && addUserStatus === "error") {
      if (addUserError.includes("UsernameExistsException")) {
        setError(USER_EXISTS);
      } else if (addUserError.includes("UserLambdaValidationException")) {
        setError(INVALID_DOMAIN);
      } else {
        setError(addUserError);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUserResponse, statusCode, addUserStatus, addUserError]);

  useLayoutEffect(() => {
    if (updateUserResponse !== typeof undefined && (updateUserStatusCode === 200 || updateUserStatusCode === 204)) {
      toast.info("User Updated");
      setTimeout(() => {
        navigate(`/${version}/users`, { replace: true });
      }, 1000);
    }

    if (updateUserResponse !== typeof undefined && updateUserStatusCode === 400) {
      toast.error(USER_NOT_EXIST);
    }

    if (updateUserResponse !== typeof undefined && updateUserStatus === "error") {
      toast.error(updateUserError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserResponse, updateUserStatusCode, updateUserStatus, updateUserError]);

  const confirm = (confirmation: string) => {
    toggle();
    if (confirmation === ModelConfirmation.Yes) {
      setIsDirty(false);
      navigate(`/${version}/users`);
    }
  };

  const v2Params = () => {
    return version === "v2" ? `?${USER_ID}=${id}` : "";
  };

  useEffect(() => {
    if (!isShowing && isDirty) {
      window.history.pushState(null, null, window.location.pathname + "#/user" + v2Params());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowing]);

  const handleBackButtonEvent = (event: PopStateEvent) => {
    event.preventDefault();
    if (isDirty) {
      toggle();
    } else {
      navigate(`/${version}/users`);
    }
  };

  const onRoleChange = (selected: any, event: any): void => {
    if (event.action !== "clear") {
      setRole(selected.value);
    } else {
      setRole(null);
    }
  };
  const onEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  const confirmStateChanges = (confirmation: string) => {
    toggleUserStateWarning();
    if (confirmation === ModelConfirmation.Yes) {
      setShowingStateWarning(false);

      handleEditUser();
    } else {
      toggleUserStateWarning();
    }
  };

  const onUserStateChange = (selected: any, event: any): void => {
    if (event.action !== "clear") {
      setUserState(selected.value);

      if (!selected.value) {
        setDeactivationCode(UpdateUserParams2DeactivationCodeEnum.NoLongerWithPostoffice);
      }
    } else {
      setUserState(userData.enabled);
      setDeactivationCode(null);
    }

    setChooseDeactivationCode(false);
    setIsDirtyState(false);
    if (userData.enabled !== selected.value) {
      setIsDirtyState(true);
    }
  };

  const onDeactivationCodeChange = (selected: any, event: any): void => {
    if (event.action !== "clear") {
      setDeactivationCode(selected.value);
    } else {
      setDeactivationCode(null);
    }
  };

  useEffect(() => {
    if (isDirty) {
      window.history.pushState(null, null, window.location.pathname + "#/user" + v2Params());
      window.addEventListener("popstate", handleBackButtonEvent);
      return () => {
        window.removeEventListener("popstate", handleBackButtonEvent);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    if (location.hash.includes("#/user")) {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDidMountEffect(() => {
    //Do not set dirty, if data is filled from edit api
    if (modalStatus === "EDIT" && !editDataFetched.current) {
      return;
    }
    setIsDirty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, role, userState, deactivationCode, modalStatus]);

  return {
    modalStatus,
    id,
    email,
    role,
    userState,
    deactivationCode,
    isShowing,
    isShowingStateWarning,
    toggleUserStateWarning,
    confirmStateChanges,
    handleUpdateButton,
    error,
    onEmailChange,
    onRoleChange,
    onUserStateChange,
    onDeactivationCodeChange,
    handleAddUser,
    handleEditUser,
    toggle,
    cancel,
    confirm,
  };
};
