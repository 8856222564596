(window as unknown as { _env: Record<string, string> })._env ??= {};
type Config = {
  version?: "v1" | "v2";
  baseApi: string;
  refDataApi: string;
};
const environment =
  process.env.NODE_ENV === "test" || process.env.NODE_ENV === "development"
    ? process.env
    : (window as unknown as { _env: Record<string, string> })._env;

const baseApiUrl = `https://api.spm-${environment.REACT_APP_ENV}.com/device-management`;

const refDataApiUrl = `https://api.spm-${environment.REACT_APP_ENV}.com/RefData`;

export const launchDarklyKey = environment.REACT_APP_LD_KEY;

const apiConfig: Config = {
  baseApi: baseApiUrl,
  refDataApi: refDataApiUrl,
};

export default apiConfig;

export const awsConfig = {
  Auth: {
    mandatorySignIn: true,
    region: environment.REACT_APP_REGION,
    userPoolId: environment.REACT_APP_USERPOOLID,
    userPoolWebClientId: environment.REACT_APP_WEBCLIENTID,
  },
};
