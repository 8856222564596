import { useApi } from "./useApi";
import { getDeviceHistoryApiClient as deviceApiClient } from "../api-helper/apiHelper";

export const useDeviceHistoryApi = (
  limit: number,
  page: number,
  searchText: string | undefined,
  sortBy: string,
  sortDirection: string,
) => {
  const [{ data: historyData }, history, historyRefresh] = useApi(
    deviceApiClient,
    [limit, page, sortBy, sortDirection, searchText],
    "",
    "GET",
  );
  const getHistory = async () => {
    historyData ? historyRefresh() : history("v2GetDevicesHistory");
  };

  return {
    getHistory,
    historyData,
  };
};
