import { FC } from "react";
import WarningIcon from "../assets/images/warning.png";
import ErrorIcon from "../assets/images/error.png";
import { AlertsTypeEnum } from "../openapi/device-api/api";

export interface AlertsIconProps {
  type: AlertsTypeEnum;
}

const AlertsIconComponent: FC<AlertsIconProps> = ({ type }): JSX.Element => {
  return type === AlertsTypeEnum.Warning ? (
    <img data-testid={`test-${type}`} className="block w-auto h-5" src={WarningIcon} alt={AlertsTypeEnum.Warning} />
  ) : (
    <img data-testid={`test-${type}`} className="block w-auto h-5" src={ErrorIcon} alt={AlertsTypeEnum.Error} />
  );
};

export default AlertsIconComponent;
