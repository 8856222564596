import { FC, useRef } from "react";
import Select from "react-select";
import { Check, X } from "heroicons-react";
import Modal from "../Modal";
import { CREATE_USER, UPDATE_USER, roleOptions, userStateOptions, userDeactivationCodeOptions } from "../../constants";
import { V2UseAddEditUser as useAddEditUser } from "../../lib/hooks/v2/useAddEditUser";
import Cross from "../../assets/images/cross.png";

const V2UserAddModal: FC = (): JSX.Element => {
  const { userDetails, error, onUserDataChange, handleAddUser, handleUpdateButton, handleCancelButton, modalData } =
    useAddEditUser();

  const { modalStatus, id, email, role, enable, deactivationCode } = userDetails;
  const {
    isShowCancelModal,
    isShowStateModal,
    toggleCancelWarning,
    confirmCancel,
    toggleStateWarning,
    confirmStateChanges,
  } = modalData;

  const state = useRef<boolean>(enable);

  return (
    <div className="">
      <dl className="flex-grow p-8 divide-y divide-gray-50">
        <input value={id} type="hidden" name="id" id="id" />
        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">
            User Email Address {<span className="text-pol-red">*</span>}
          </dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                disabled={modalStatus === "EDIT"}
                value={email}
                onChange={(e) => onUserDataChange("email", e.target.value)}
                placeholder="Enter email address"
                type="text"
                name="email"
                id="email"
                className={
                  error
                    ? "edit-data-input-error sm:text-sm disabled:cursor-pointer disabled:"
                    : "edit-data-input sm:text-sm disabled:cursor-pointer disabled:"
                }
              />
              {error && (
                <div className="inline-flex mt-1 ml-1">
                  <div className="flex items-center flex-shrink-0">
                    <img className="block w-auto h-3" src={Cross} alt="cross" />
                  </div>
                  <div className="ml-2 text-red-500">{error}</div>
                </div>
              )}
            </span>
          </dd>
        </div>

        <div className="edit-data-row sm:pt-5">
          <dt className="text-sm font-medium text-gray-700">
            User Role <span className="text-pol-red">*</span>
          </dt>
          <dd className="flex mt-1 overflow-visible text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <Select
              onChange={(e) => onUserDataChange("role", e.value)}
              options={roleOptions}
              value={roleOptions.find((item) => item.value === role)}
              isSearchable
              maxMenuHeight={200}
              className="flex-1 capitalize"
            />
          </dd>
        </div>
        <div className="edit-data-row sm:pt-5">
          <dt className="text-sm font-medium text-gray-700">
            Status <span className="text-pol-red">*</span>
          </dt>
          <dd className="flex mt-1 overflow-visible text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <Select
              onChange={(e) => onUserDataChange("enabled", e.value)}
              options={userStateOptions}
              value={userStateOptions.find((item) => item.value === enable)}
              isSearchable
              maxMenuHeight={200}
              className="flex-1 capitalize"
              isDisabled={modalStatus === "ADD"}
            />
          </dd>
        </div>
        {!enable && modalStatus === "EDIT" && (
          <div className="edit-data-row sm:pt-5">
            <dt className="text-sm font-medium text-gray-700">
              Reason codes <span className="text-pol-red">*</span>
            </dt>
            <dd className="flex mt-1 overflow-visible text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <Select
                onChange={(e) => onUserDataChange("deactivationCode", e.value)}
                options={userDeactivationCodeOptions}
                value={userDeactivationCodeOptions.find((item) => item.value === deactivationCode)}
                isSearchable
                maxMenuHeight={200}
                className="flex-1 capitalize"
              />
            </dd>
          </div>
        )}
      </dl>
      <div className="flex flex-row-reverse items-end flex-grow px-4 py-3 mt-96 bg-white-50 sm:px-6">
        <button onClick={handleCancelButton} type="button" className="ml-4 btn btn-cancel">
          <X className="inline" size={18} /> Cancel
        </button>

        {modalStatus === "ADD" ? (
          <button onClick={handleAddUser} type="button" className="ml-4 btn btn-success">
            <Check className="inline" size={18} /> {CREATE_USER}
          </button>
        ) : (
          <button onClick={handleUpdateButton} type="button" className="ml-4 btn btn-success">
            <Check className="inline" size={18} /> {UPDATE_USER}
          </button>
        )}
      </div>

      {/* Checks the useModal Hook to see if modal is showing */}
      {isShowCancelModal && (
        <Modal
          isShowing={isShowCancelModal}
          hide={toggleCancelWarning}
          confirmation={confirmCancel}
          modalBody={<div>Are you sure you want to leave the page?</div>}
        />
      )}

      {isShowStateModal && (
        <Modal
          isShowing
          hide={toggleStateWarning}
          confirmation={confirmStateChanges}
          modalBody={
            <div>
              {state.current
                ? `Are you sure you want to deactivate the user?`
                : `Are you sure you want to activate the user?`}
            </div>
          }
        />
      )}
    </div>
  );
};

export default V2UserAddModal;
