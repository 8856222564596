import React, { FC, useContext, useEffect, useRef, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { PencilAltOutline, UserAddOutline } from "heroicons-react";
import { UsersResponse, User } from "../lib/models";
import { AppContext } from "../context/store";
import { ADMIN, ModelConfirmation } from "../constants";
import { SearchBox } from "./table/searchBox";
import { ActionType } from "../context/reducer";
import Modal from "./Modal";
import { User as UserRecord } from "../openapi/device-api/api";
import { useAppVersion } from "../lib/hooks/useAppVersion";
import { formatDate } from "../components/helpers";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ROWS_PER_PAGE_V2 } from "../constants";
import { loadingSpinner } from "./loadingSpinner";
export interface UserTableProps {
  rows: UsersResponse;
  searchCallback?: (search: string) => void;
  addEditUserCallback: (user: User) => void;
  userExport?: () => void;
  totalRows?: number;
  handlePageChange?: (page: number) => void;
  handleRowsPerPageChange?: (newPage: number, page: number) => void;
  handleSorting?: (rows, selector, direction) => any;
}

const UserTableComp: FC<UserTableProps> = ({
  rows,
  searchCallback,
  addEditUserCallback,
  userExport,
  totalRows,
  handlePageChange,
  handleRowsPerPageChange,
  handleSorting,
}): JSX.Element => {
  const { state, dispatch } = useContext(AppContext);

  let [searchParams, setSearchParams] = useSearchParams();
  const limit = searchParams.get("limit");
  const searchString = searchParams.get("q");
  const page = Number(searchParams.get("page"));
  const searchRef = useRef<HTMLInputElement>(null);
  const [isExportModalOpen, setExportModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.split("/").pop() === "export") {
      setExportModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // to maintain history when clicking a browser back button
    // if there is no q param in the url, reset search string
    if (!searchString && searchRef.current.value.length > 0) {
      searchRef.current.value = null;
    }
    // if there is q param in the url, populate search field as well
    if (searchString && !searchRef.current.value.length) {
      searchRef.current.value = searchString;
    }
  }, [searchString]);

  const { version } = useAppVersion();

  const handleOnAddEditUser = (userRow: User) => {
    dispatch({ type: ActionType.SET_USER, payload: userRow });
    addEditUserCallback(userRow);
  };

  const isAdmin = (): boolean => {
    return state.userRole === ADMIN;
  };

  const createActions = (row: User): JSX.Element => {
    return <>{isAdmin() && editButton(row)}</>;
  };

  const handleKeyUp = (e: { key: string }) => {
    if (
      (e.key === "Enter" && searchRef.current.value && searchRef.current.value.length > 2) ||
      searchRef.current.value.length === 0
    ) {
      if (!searchRef.current.value.length) {
        // reset search when all characters are deleted from search field
        onReset();
      } else {
        searchCallback(searchRef.current.value);
      }
    }
  };

  const onReset = () => {
    searchParams.delete("q");
    setSearchParams(searchParams);
    searchRef.current.value = null;
    searchCallback("");
  };

  const exportDataConfirm = (confirmation: string) => {
    setExportModalOpen(false);
    if (confirmation === ModelConfirmation.Yes) {
      userExport();
    }
  };

  const subHeaderComponentMemo = React.useMemo((): JSX.Element => {
    return (
      <div className="flex w-full py-4">
        <div className="flex-1 min-w-0">
          {isAdmin() && (
            <div className="inline-flex">
              <button
                type="button"
                data-cy="add-modal-button"
                className="inline-flex items-center btn btn-clear"
                onClick={(): void => handleOnAddEditUser({} as User)}
              >
                <UserAddOutline size={18} className="mr-2" /> Add User
              </button>
              {version === "v2" && (
                <button
                  type="button"
                  data-cy="export-modal-button"
                  className="inline-flex items-center btn btn-clear ml-4"
                  onClick={() => {
                    navigate(`/${version}/users/export`);
                    setExportModalOpen(true);
                  }}
                >
                  Export CSV
                </button>
              )}
            </div>
          )}
        </div>
        <div className="inline-flex items-end w-1/4">
          <SearchBox
            ref={searchRef}
            onKeyUp={handleKeyUp}
            onReset={onReset}
            defaultValue={searchString}
          />
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.userRole, searchString]);

  // Edit button in datatable
  const editButton = (row: User): JSX.Element => {
    return (
      <button
        type="button"
        className="inline-flex items-center btn btn-clear mr-2"
        onClick={(): void => handleOnAddEditUser(row)}
      >
        <PencilAltOutline size={18} className="mr-2" /> Edit
      </button>
    );
  };

  const rowDisabledStyle = {
    when: (row) => !row.enabled && version === "v2",
    classNames: ["text-white", "bg-gray-300", "cursor-not-allowed", "border-gray-300"],
  };

  // Creates datatable columns, includes formatting of date and sortable bool
  const newCols = React.useMemo(
    () => [
      {
        name: "Username",
        selector: (row) => row.username,
        sortable: true,
        sortField: "username",
        maxWidth: "300px",
        conditionalCellStyles: [rowDisabledStyle],
      },
      {
        name: "Email ID",
        selector: (row) => row.email,
        sortable: true,
        sortField: "email",
        maxWidth: "300px",
        conditionalCellStyles: [rowDisabledStyle],
      },
      {
        name: "Status",
        selector: (row) => row.user_status,
        sortable: true,
        sortField: "user_status",
        maxWidth: "300px",
        conditionalCellStyles: [rowDisabledStyle],
      },
      {
        name: "Last Modified on",
        selector: (row) => row.modified_at,
        cell: (row: User) => {
          return formatDate(row.modified_at, "MMM DD, YYYY HH:mm:ss");
        },
        sortable: true,
        sortField: "modified_at",
        maxWidth: "300px",
        conditionalCellStyles: [rowDisabledStyle],
      },
      {
        name: "Role",
        selector: (row) => row.role,
        sortable: true,
        sortField: "role",
        maxWidth: "150px",
        conditionalCellStyles: [rowDisabledStyle],
      },
      {
        name: "Actions",
        button: true,
        cell: (row: User) => createActions(row),
        right: true,
        minWidth: "150px",
        omit: !isAdmin(),
        conditionalCellStyles: [rowDisabledStyle],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.userRole]
  ) as unknown as TableColumn<UserRecord>[];

  return (
    <div data-testid="dataTable">
      <DataTable
        className="data-table"
        columns={newCols}
        data={rows.users}
        pagination
        highlightOnHover
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        defaultSortFieldId="username"
        defaultSortAsc
        {...(version === "v2" && {
          paginationServer: true,
          paginationTotalRows: totalRows,
          onChangeRowsPerPage: handleRowsPerPageChange,
          onChangePage: handlePageChange,
          data: rows.items,
          onSort: handleSorting,
          sortServer: true,
          paginationPerPage: limit ? Number(limit) : ROWS_PER_PAGE_V2,
          paginationDefaultPage: page > 0 ? page : 1,
          ...(totalRows === undefined
            ? {
                progressPending: true,
                progressComponent: loadingSpinner("Loading users..."),
              }
            : ""),
        })}
      />
      {isExportModalOpen && (
        <Modal
          isShowing={isExportModalOpen}
          hide={null}
          confirmation={exportDataConfirm}
          modalBody={<div>Do you want to save users CSV file on your machine?</div>}
        />
      )}
    </div>
  );
};

export default UserTableComp;
