import {
  BRANCH_ID_DIGIT_REGEXP,
  BRANCH_ID_REGEXP,
  ERROR_MESSGAES,
  DEVICE_ID_MAX_CHARACTERS,
  INPUT_BOX_MIN_CHARACTERS,
  DEVICE_NAME_MAX_CHARACTERS,
  NOTES_MAX_CHARACTERS,
} from "../constants";

interface Validator {
  validate: (data: Record<string, unknown>, skipFields?: string[]) => string[];
}

const NODE_ID_REGEXP = new RegExp(/^[3][2-9]$|^[4-5][0-9]$|^[6][0-3]$/i);

const validateId = (id: string) => {
  const errors = [];
  if (String(id).indexOf(" ") !== -1) {
    errors.push(ERROR_MESSGAES.DEVICE_ID_SPACE_CHAR_ERROR);
  }

  if (id === undefined || String(id).length < INPUT_BOX_MIN_CHARACTERS) {
    errors.push(ERROR_MESSGAES.DEVICE_ID_MIN_CHAR_ERROR);
  }

  if (String(id).length > DEVICE_ID_MAX_CHARACTERS) {
    errors.push(ERROR_MESSGAES.DEVICE_ID_MAX_CHAR_ERROR);
  }

  return errors;
};

const validateName = (name: string) => {
  const errors = [];
  if (name === undefined || String(name).length < INPUT_BOX_MIN_CHARACTERS) {
    errors.push(ERROR_MESSGAES.DEVICE_NAME_MIN_CHAR_ERROR);
  }

  if (String(name).length > DEVICE_NAME_MAX_CHARACTERS) {
    errors.push(ERROR_MESSGAES.DEVICE_NAME_MAX_CHAR_ERROR);
  }
  return errors;
};

const validateBranchId = (branchId: string, branchValid: string) => {
  if (
    branchId === undefined ||
    !(BRANCH_ID_REGEXP.test(String(branchId)) || BRANCH_ID_DIGIT_REGEXP.test(String(branchId)))
  ) {
    return [ERROR_MESSGAES.BRANCH_ID_ERROR];
  } else if (!branchValid) {
    return [ERROR_MESSGAES.BRANCH_ID_CHANGE_ERROR];
  }

  return [];
};

const validateNodeId = (nodeId: string, skipFields: string[]) => {
  const errors = [];
  if (!skipFields || !skipFields.includes("node_id")) {
    if (nodeId !== undefined && nodeId !== null && String(nodeId).length > 2) {
      errors.push(ERROR_MESSGAES.NODE_ID_MAX_CHAR_ERROR);
    }

    if (!NODE_ID_REGEXP.test(String(nodeId))) {
      errors.push(ERROR_MESSGAES.NODE_ID_ERROR);
    }
  }
  return errors;
};

const validateType = (type: string) => {
  if (type === undefined || String(type).trim() === "") {
    return [ERROR_MESSGAES.DEVICE_TYPE_ERROR];
  }
  return [];
};

const validateNotes = (notes: string) => {
  if (String(notes).length > NOTES_MAX_CHARACTERS) {
    return [ERROR_MESSGAES.NOTES_MAX_CHAR_ERROR];
  }

  return [];
};

const validateDeactivationCode = (data: Record<string, unknown>) => {
  if (data["orgDeviceState"] === "active" && data["state"] === "inactive" && !data["chooseDeactivationCode"]) {
    return [ERROR_MESSGAES.DEVICE_DEACTIVATION_CODE_ERROR];
  }

  return [];
};

const deviceValidator = (data: Record<string, unknown>, skipFields?: string[]): string[] => {
  return [
    ...validateId(data["id"] as string),
    ...validateName(data["name"] as string),
    ...validateType(data["type"] as string),
    ...validateNotes(data["notes"] as string),
    ...validateDeactivationCode(data),
    ...validateBranchId(data["branch_id"] as string, data["branchValid"] as string),
    ...validateNodeId(data["node_id"] as string, skipFields),
  ];
};

const buildValidator = (
  entityValidator: (data: Record<string, unknown>, skipFields: string[]) => string[],
): Validator => {
  return Object.freeze({ validate: entityValidator });
};

export default buildValidator(deviceValidator);
