import { FC } from "react";
import Page from "../components/layout/Page";
import DeviceHistoryTableComp from "../components/DeviceHistoryTableComp";
import { useHistory } from "../lib/hooks/useDeviceHistory";
import { ROWS_PER_PAGE_V2 } from "../constants";
import { formatDate, manipulateObject } from "../components/helpers";
import { HistoryRecord } from "../lib/models";
import RecordListComponent from "../components/RecordListComp";

enum FIELDS_MAPPING {
  name = "Device Name",
  type = "Device Type",
  node_id = "Node ID",
  branch_id = "Branch ID",
  notes = "Device Notes",
  created_at = "Device added on",
  created_by = "Device added by",
  modified_at = "Device edited on",
  modified_by = "Device edited by",
  last_otp_at = "Latest OTP generated on",
  last_otp_by = "Latest OTP generated by",
  state = "Device state",
  deactivation_reason = "Reason Code",
}

const dateFields = {
  created_at: 1,
  modified_at: 1,
  last_otp_at: 1,
};

const isDate = (field: string) => {
  return dateFields[field];
};

export const mappingObject = (data: Record<string, string>[]) => {
  const mappedData = data.reduce((obj, item) => {
    obj.push({
      key: FIELDS_MAPPING[item.key],
      value: isDate(item.key) ? formatDate(item.value, "DD/MM/YYYY HH:mm:ss") : item.value,
    });
    return obj;
  }, []);

  return mappedData;
};

const objectRowStyle: Readonly<{ [key: string]: string }> = {
  "flex-direction": "column",
  "align-items": "flex-start",
};

const rowStyle: Readonly<{ [key: string]: string }> = {
  "align-items": "flex-start",
  margin: "10px 0 10px 0;",
};

const colNames = [
  {
    name: "Device ID",
    selector: (row) => row.device_id,
    sortField: "device_id",
    wrap: true,
    sortable: true,
    maxWidth: "135px",
    style: rowStyle,
  },
  {
    name: "Terminal Type",
    selector: (row) => row.type,
    sortField: "type",
    sortable: true,
    maxWidth: "130px",
    style: rowStyle,
  },
  {
    name: "Attribute",
    selector: (row) => row.state_change_before,
    maxWidth: "185px",
    style: objectRowStyle,
    cell: (row: HistoryRecord) => (
      <RecordListComponent
        list={mappingObject(manipulateObject(row.state_change_before as unknown as Record<string, string>))}
        valueType="key"
      />
    ),
  },
  {
    name: "Previous Value",
    selector: (row) => row.state_change_before,
    maxWidth: "390px",
    style: objectRowStyle,
    cell: (row: HistoryRecord) => (
      <RecordListComponent
        list={mappingObject(manipulateObject(row.state_change_before as unknown as Record<string, string>))}
        valueType="value"
      />
    ),
  },
  {
    name: "Latest Value",
    selector: (row) => row.state_change_after,
    maxWidth: "390px",
    style: objectRowStyle,
    cell: (row: HistoryRecord) => (
      <RecordListComponent
        list={mappingObject(manipulateObject(row.state_change_after as unknown as Record<string, string>))}
        valueType="value"
      />
    ),
  },
  {
    name: "Last Modified",
    selector: (row) => row.modified_at,
    sortField: "modified_at",
    cell: (row: HistoryRecord) => formatDate(row.modified_at, "MMM DD, YYYY HH:mm:ss"),
    sortable: true,
    maxWidth: "160px",
    style: rowStyle,
  },
  {
    name: "Modified By",
    selector: (row) => row.modified_by,
    sortField: "modified_by",
    sortable: true,
    maxWidth: "155px",
    wrap: true,
    style: rowStyle,
  },
];

const DeviceHistory: FC = (): JSX.Element => {
  const history = useHistory({ defaultPerPage: ROWS_PER_PAGE_V2, defaultPage: 1, columns: colNames });

  return (
    <Page>
      <DeviceHistoryTableComp
        history={history}
      />
    </Page>
  );
};

export default DeviceHistory;
