import { useEffect, useReducer } from "react";
import { useDeviceHistoryApi } from "./useDeviceHistoryApi";
import { useSearchParams } from "react-router-dom";
import {
  changePerPageAction,
  changePageAction,
  changeQueryAction,
  setDataAction,
  pageReducer,
  PageState,
  ChangePerPageAction,
  ChangePageAction,
  changeParamsAction,
  changeSortAction,
} from "../../components/pageReducer";
import { TableColumn } from "react-data-table-component";

export const initialPageState = <D, C>(): PageState<D, C> => {
  return {
    status: "not_loading",
    perPage: 10,
    page: 1,
    data: undefined,
    totalRows: undefined,
    query: undefined,
    columns: [] as C[],
    sortColumn: undefined,
    sortOrder: undefined,
  };
};


export interface PaginableTable<DataType, ColumnType> {
  page: number;
  data: DataType[] | undefined;
  columns: ColumnType[];
  totalRows: number;
  perPage: number;
  onChangeRowPerPage: (perPage: number, page: number) => ChangePerPageAction;
  onChangePage: (page: number) => ChangePageAction;
  onSearch: (query: string) => void;
  onSort: (column: TableColumn<any>, sortDirection: string) => void;
}

export const useHistory = <DataType, ColumnType>(props: {
  defaultPage: number;
  defaultPerPage: number;
  columns: ColumnType[];
}): PaginableTable<DataType, ColumnType> => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { defaultPage, defaultPerPage, columns } = props;

  const [page, pageDispatch] = useReducer(pageReducer, {
    ...initialPageState(),
    page: parseInt(searchParams.get("page"), 10) || defaultPage,
    perPage: parseInt(searchParams.get("limit"), 10) || defaultPerPage,
    columns,
  } as PageState<DataType, ColumnType>);

  const buildParams = (urlParams, defaultPerPage, defaultPage) => {
    if (Array.from(urlParams).length) {
      let limit;
      let pageNumber;
      let q;
      let sortBy;
      let sortDirection;
      if (urlParams.get("limit")) {
        limit = Number(urlParams.get("limit"));
      }
      if (urlParams.get("page")) {
        pageNumber = Number(urlParams.get("page"));
      }
      if (urlParams.get("q")) {
        q = urlParams.get("q");
      }
      if (urlParams.get("sortBy")) {
        sortBy = urlParams.get("sortBy");
      }
      if (urlParams.get("sortDirection")) {
        sortDirection = urlParams.get("sortDirection");
      }

      const params: {
        query?: string | undefined;
        perPage?: number | undefined;
        page?: number | undefined;
        sortBy?: string | undefined;
        sortDirection?: string | undefined;
      } = {
        query: q,
        perPage: limit ? limit : defaultPerPage,
        page: pageNumber ? pageNumber : defaultPage,
        sortBy: sortBy,
        sortDirection: sortDirection,
      };
      return params;
    }
  };

  const { historyData, getHistory } = useDeviceHistoryApi(
    page.perPage,
    page.page,
    page.query,
    page.sortColumn,
    page.sortOrder,
  );

  const onChangeRowPerPage = (perPage: number, page: number) => {
    searchParams.set("limit", String(perPage));
    searchParams.set("page", String(page));
    setSearchParams(searchParams);
    const action = changePerPageAction(perPage, page);
    pageDispatch(action);
    return action;
  };

  const onChangePage = (page: number): ChangePageAction => {
    searchParams.set("page", String(page));
    setSearchParams(searchParams);
    const action = changePageAction(page);
    pageDispatch(action);
    return action;
  };

  const onSearch = (newQuery: string) => {
    searchParams.set("q", newQuery);
    searchParams.set("page", String(defaultPage));
    setSearchParams(searchParams);
    pageDispatch(changeQueryAction(newQuery));
  };

  const onSort = (column: TableColumn<any>, sortDirection: string) => {
    searchParams.set("sortBy", column.sortField);
    searchParams.set("sortDirection", sortDirection);
    setSearchParams(searchParams);
    pageDispatch(changeSortAction(column.sortField, sortDirection));
  };

  useEffect(() => {
    const getData = async () => {
      await getHistory();
    };
    if (page.status === "not_loading") {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const params = buildParams(searchParams, defaultPerPage, defaultPage);
    if (params) {
      pageDispatch(changeParamsAction(params));
    }
  }, [defaultPage, defaultPerPage, searchParams]);

  useEffect(() => {
    const getData = async () => {
      await getHistory();
    };
    if (page.status === "loading") {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.status]);

  useEffect(() => {
    if (historyData && Array.isArray(historyData.items)) {
      pageDispatch(setDataAction(historyData.items, historyData.pagination?.total));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyData]);

  return {
    page: page.page,
    data: page.data as DataType[],
    columns: page.columns as ColumnType[],
    totalRows: page.totalRows,
    perPage: page.perPage,
    onChangeRowPerPage,
    onChangePage,
    onSearch,
    onSort,
  };
};
