import { FC, useContext, useLayoutEffect } from "react";
import Page from "../components/layout/Page";
import UserTableComp from "../components/UserTableComp";
import { useUsers } from "../lib/hooks/useUsers";
import { User } from "../lib/models";
import { AppContext } from "../context/store";
import { useNavigate } from "react-router-dom";
import { useAppVersion } from "../lib/hooks/useAppVersion";

const UserManagement: FC = (): JSX.Element => {
  const { state } = useContext(AppContext);

  const navigate = useNavigate();
  const {
    retrieveUsers,
    searchUser,
    exportUserData,
    totalRowsUsers,
    handlePageChange,
    handleRowsPerPageChange,
    handleSorting,
  } = useUsers();
  const { version } = useAppVersion();

  useLayoutEffect(() => {
    state.users = [];
    retrieveUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const id = "id";

  const navigateToAddEditUser = (user?: User) => {
    if (user.username) {
      return version === "v2"
        ? navigate(`/${version}/users/${user.id}/edit?${id}=${user.id}`)
        : navigate(`/${version}/users/${user.username}/edit`);
    }

    navigate(`/${version}/users/add`);
  };

  return (
    <Page>
      <UserTableComp
        rows={state.users}
        searchCallback={searchUser}
        addEditUserCallback={navigateToAddEditUser}
        userExport={exportUserData}
        totalRows={totalRowsUsers}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        handleSorting={handleSorting}
      />
    </Page>
  );
};

export default UserManagement;
