import React, { FC, useState, useLayoutEffect, useRef, useContext, useEffect } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import moment from "moment";
import { PencilAltOutline, UserAddOutline, Eye } from "heroicons-react";
import { Device } from "../lib/models";
import { SearchBox } from "./table/searchBox";
import { ActionType } from "../context/reducer";
import { AppContext } from "../context/store";
import Otp from "./table/Otp";
import Modal from "./Modal";
import { useModal } from "../lib/hooks/useModal";
import DeviceViewModal from "./DeviceViewModal";
import V2DeviceViewModal from "./v2/V2DeviceViewModal";
import { ADMIN, ModelConfirmation } from "../constants";
import { useExportDevices } from "../lib/hooks/useExportDevices";
import { CSVLink } from "react-csv";
import NoResultComponent from "./NoResultComp";
import { Device2StateEnum } from "../openapi/device-api/api";
import useUserStatus from "../lib/hooks/useUserStatus";
import { useAppVersion } from "../lib/hooks/useAppVersion";
import { formatDate } from "./helpers";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ROWS_PER_PAGE_V2 } from "../constants";
import { useAddEditDevice } from "../lib/hooks/useAddEditDevice";
import { loadingSpinner } from "./loadingSpinner";

export interface DeviceTableProps {
  action?: string;
  rows: Device[];
  searchCallback?: (search: string) => void;
  addEditDevicecallback: (deviceID: string) => void;
  totalRows?: number;
  handleSorting?: (column: TableColumn<any>, sortDirection: string) => void;
  handlePageChange?: (page: number) => void;
  handleRowsPerPageChange?: (newPage: number, page: number) => void;
}

const DeviceTableComp: FC<DeviceTableProps> = ({
  rows,
  searchCallback,
  addEditDevicecallback,
  totalRows,
  handlePageChange,
  handleRowsPerPageChange,
  handleSorting,
}): JSX.Element => {
  const searchRef = useRef<HTMLInputElement>(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const { state, dispatch } = useContext(AppContext);
  const userStatus = useUserStatus();
  const { version } = useAppVersion();
  const { isShowing, toggle } = useModal();
  const [isViewPage, setViewPage] = useState(false);
  const [device, setDevice] = useState<Device>();
  const [isExportModal, setExportModal] = useState(false);
  const [exportFileName, setExportFileName] = useState("");
  const csvExportRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const searchString = searchParams.get("q");
  const page = Number(searchParams.get("page"));

  const { deviceDetailApiResponse } = useAddEditDevice({
    pageType: "EDIT",
  });
  const findDevice = (action) => filteredItems.find((e) => e.id === action);

  useEffect(() => {
    if (location.pathname.split("/").pop() === "export") {
      setExportModal(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    // to maintain history when clicking a browser back button
    // if there is no q param in the url, reset search string
    if (!searchString && searchRef.current.value.length > 0) {
      searchRef.current.value = null;
    }
    // if there is q param in the url, populate search field as well
    if (searchString && !searchRef.current.value.length) {
      searchRef.current.value = searchString;
    }
  }, [searchString]);

  // handling modals: view, otp and export
  useEffect(() => {
    const urlPath = location.pathname.split("/");
    const action = urlPath.pop();
    if (action === "export") {
      setExportModal(true);
    } else if (action !== "edit" && action !== "add" && filteredItems) {
      // view device
      const foundDevice = action === "otp" ? findDevice(urlPath[-1]) : findDevice(action);
      // if the device id is in filteredItems, pass it to the fn for loading modal
      if (foundDevice) {
        action === "otp" ? handleOTPClick(foundDevice) : handleViewPopup(foundDevice);
      } else {
        // else, device is not on the page, use API call to fetch data and pass it to the fn for loading modal
        if (deviceDetailApiResponse) {
          action === "otp" ? handleOTPClick(deviceDetailApiResponse) : handleViewPopup(deviceDetailApiResponse);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, deviceDetailApiResponse]);

  const { deviceHeaders, exportedDevices, exportedDevicesV2 } = useExportDevices();

  // sets row data into the datatable when data changes
  useLayoutEffect(() => {
    setFilteredItems(rows);
  }, [rows]);

  useLayoutEffect(() => {
    if (exportFileName) {
      csvExportRef.current.link.click();
    }
  }, [exportFileName]);

  const handleKeyUp = (e: { key: string }) => {
    if (
      (e.key === "Enter" && searchRef.current.value && searchRef.current.value.length > 2) ||
      searchRef.current.value.length === 0
    ) {
      if (!searchRef.current.value.length) {
        // reset search when all characters are deleted from search field
        onReset();
      } else {
        searchCallback(searchRef.current.value);
      }
    }
  };

  const onReset = () => {
    if (searchParams) {
      if (searchParams.get("page")) {
        searchParams.set("page", "1");
      }
      searchParams.delete("q");
      setSearchParams(searchParams);
    }
    searchRef.current.value = null;
    searchCallback("");
  };

  // Opens device page with current selected device
  const handleOnEditClick = (row: Device) => {
    dispatch({ type: ActionType.SET_DEVICE, payload: row });
    addEditDevicecallback(row.id);
  };

  // Opens OTP generation pop-up
  const handleOTPClick = (row: Device) => {
    window.history.pushState(null, "", `/${version}/devices/${row.id}/otp`);
    setDevice(row);
    toggle();
  };

  //Opens View_page pop-up
  const handleViewPopup = (row: Device) => {
    window.history.pushState(null, "", `/${version}/devices/${row.id}`);
    setDevice(row);
    setViewPage(true);
  };

  // Edit button in datatable
  const editButton = (row: Device): JSX.Element => {
    return (
      <button
        type="button"
        className="inline-flex items-center btn btn-clear mr-2"
        onClick={(): void => handleOnEditClick(row)}
      >
        <PencilAltOutline size={18} className="mr-2" /> Edit
      </button>
    );
  };

  const generateOtpButton = (row: Device): JSX.Element => {
    return (
      <button
        type="button"
        className={`inline-flex items-center btn btn-clear mr-2 ${
          row.state === Device2StateEnum.Inactive ? "btn-disable-white pointer-events-none" : ""
        }`}
        onClick={(): void => handleOTPClick(row)}
      >
        Generate OTP
      </button>
    );
  };

  // View Button in dataTable
  const viewButton = (row: Device): JSX.Element => {
    return (
      <button
        type="button"
        className="inline-flex items-center btn btn-clear mr-2"
        onClick={(): void => {
          handleViewPopup(row);
        }}
      >
        <Eye size={18} className="mr-2" /> View
      </button>
    );
  };

  const createActions = (row: Device): JSX.Element => {
    return (
      <>
        {viewButton(row)}
        {((userStatus && userStatus.attributes["custom:role"] === ADMIN) || state.userRole === ADMIN) &&
          editButton(row)}
        {generateOtpButton(row)}
      </>
    );
  };

  const exportDataConfirm = (confirmation: string) => {
    setExportModal(false);
    if (confirmation === ModelConfirmation.Yes) {
      if (version === "v2") {
        exportedDevicesV2();
      } else {
        setExportFileName(`DeviceExport_${moment(new Date()).format("DDMMYYHHmmss")}.csv`);
      }
      navigate(-1);
    } else if (confirmation === ModelConfirmation.No) {
      //navigate(`/${version}/devices`);
      navigate(-1);
    }
  };

  // memorized subComponent for data table which created data table header including search
  const subHeaderComponentMemo = React.useMemo((): JSX.Element => {
    return (
      <div className="flex w-full py-4">
        <div className="flex-1 min-w-0">
          <div className="inline-flex">
            <button
              type="button"
              data-cy="add-modal-button"
              className="inline-flex items-center btn btn-clear"
              onClick={(): void => handleOnEditClick({} as Device)}
            >
              <UserAddOutline size={18} className="mr-2" /> Add Device
            </button>
            <button
              type="button"
              data-cy="export-modal-button"
              className="inline-flex items-center btn btn-clear ml-4"
              onClick={(): void => {
                window.history.pushState(null, "", `/${version}/devices/export`);
                setExportModal(true);
              }}
            >
              Export CSV
            </button>
            <CSVLink
              ref={csvExportRef}
              data-testid="csvLink"
              headers={deviceHeaders}
              data={exportedDevices(state.devices)}
              filename={exportFileName}
              target="_blank"
            ></CSVLink>
          </div>
        </div>
        <div className="inline-flex items-end w-1/4">
          <SearchBox ref={searchRef} onKeyUp={handleKeyUp} onReset={onReset} defaultValue={searchString} />
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.devices, exportFileName, searchString]);

  // handles checking for no branch and changing row colour
  const conditionalRowStyles = [
    {
      when: (row) => row.branch_id === "",
      style: {
        backgroundColor: "seashell",
        color: "black",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "pink",
        },
      },
    },
  ];

  const hideViewModal = () => {
    setViewPage(false);
  };

  const rowDisabledStyle = {
    when: (row) => row.state === Device2StateEnum.Inactive,
    classNames: ["text-white", "bg-gray-300", "cursor-not-allowed", "border-gray-300"],
  };

  // Creates datatable columns, includes formatting of date and sortable bool
  const newCols = React.useMemo(
    () => [
      {
        name: "Device ID",
        selector: (row) => row.id,
        sortable: true,
        sortField: "device_id",
        maxWidth: "185px",
        conditionalCellStyles: [rowDisabledStyle],
      },
      {
        name: "Device Name",
        selector: (row) => row.name,
        sortable: true,
        sortField: "device_name",
        maxWidth: "185px",
        conditionalCellStyles: [rowDisabledStyle],
      },
      {
        name: "Branch Id",
        selector: (row) => row.branch_id,
        sortable: true,
        sortField: "branch_id",
        maxWidth: "185px",
        conditionalCellStyles: [rowDisabledStyle],
      },
      {
        name: "Branch Name",
        selector: (row) => row.branch_name,
        sortable: true,
        sortField: "branch_name",
        maxWidth: "185px",
        conditionalCellStyles: [rowDisabledStyle],
      },
      {
        name: "Device Type",
        selector: (row) => row.type,
        sortable: true,
        sortField: "device_type",
        maxWidth: "185px",
        conditionalCellStyles: [rowDisabledStyle],
      },
      {
        name: "Last Modified",
        selector: (row) => row.modified_at,
        cell: (row: Device) => {
          return formatDate(row.modified_at, "MMM DD, YYYY HH:mm:ss");
        },
        sortable: true,
        sortField: "modified_at",
        maxWidth: "185px",
        conditionalCellStyles: [rowDisabledStyle],
      },
      {
        name: "Actions",
        button: true,
        cell: (row: Device) => createActions(row),
        right: true,
        minWidth: "400px",
        conditionalCellStyles: [rowDisabledStyle],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.userRole],
  ) as TableColumn<Device>[];

  const limit = searchParams.get("limit");

  return (
    <div data-testid="dataTable">
      <DataTable
        className="data-table"
        columns={newCols}
        data={filteredItems}
        pagination
        highlightOnHover
        noDataComponent={<NoResultComponent message="No results found. Please note this search is case-sensitive" />}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        conditionalRowStyles={conditionalRowStyles}
        defaultSortFieldId="created_at"
        defaultSortAsc
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handleRowsPerPageChange}
        paginationPerPage={limit ? Number(limit) : ROWS_PER_PAGE_V2}
        onChangePage={handlePageChange}
        onSort={handleSorting}
        {...(version === "v2" && {
          paginationServer: true,
          sortServer: true,
          paginationDefaultPage: page > 0 ? page : 1,
          ...(totalRows === undefined
            ? {
                progressPending: true,
                progressComponent: loadingSpinner("Loading devices..."),
              }
            : ""),
        })}
      />

      {isShowing && (
        <Modal
          isShowing={isShowing}
          hide={() => {
            if (isShowing) navigate(-1);
            toggle();
          }}
          modalHeader={"Generate OTP for " + device.id}
          closeable={true}
          fullPage={true}
          modalBody={<Otp device={device} />}
        />
      )}

      {isViewPage &&
        (version === "v2" ? (
          <Modal
            isShowing={isViewPage}
            hide={() => {
              if (isViewPage) navigate(-1);
              hideViewModal();
            }}
            modalHeader={"Device Details"}
            closeable={true}
            fullPage={true}
            modalBody={<V2DeviceViewModal row={device} />}
          />
        ) : (
          <Modal
            isShowing={isViewPage}
            hide={() => {
              if (isViewPage) navigate(-1);
              hideViewModal();
            }}
            modalHeader={"Device Details"}
            closeable={true}
            fullPage={true}
            modalBody={<DeviceViewModal row={device} />}
          />
        ))}

      {isExportModal && (
        <Modal
          isShowing={isExportModal}
          hide={null}
          confirmation={exportDataConfirm}
          modalBody={<div>Do you want to save devices CSV file on your machine?</div>}
        />
      )}
    </div>
  );
};

export default DeviceTableComp;
