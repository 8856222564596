import React from "react";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import ReactDOM from "react-dom";
import { Auth } from "aws-amplify";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import "./assets/css/tailwind.css";
import "./assets/css/app.css";
import App from "./App";
import StateProvider from "./context/store";
import { awsConfig, launchDarklyKey } from "./config";

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: launchDarklyKey,
    options: {
      application: {
        id: "device-manager-ui",
      },
    },
  });
  Auth.configure(awsConfig);

  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <AmplifyProvider>
          <StateProvider>
            <App />
          </StateProvider>
        </AmplifyProvider>
      </LDProvider>
    </React.StrictMode>,
    document.getElementById("root"),
  );
})();
