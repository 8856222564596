import { FC, useContext, useLayoutEffect } from "react";
import Page from "../components/layout/Page";
import DeviceTableComp from "../components/DeviceTableComp";
import { AppContext } from "../context/store";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../lib/GlobalState";
import { useAppVersion } from "../lib/hooks/useAppVersion";
import { ActionType } from "../context/reducer";

export interface DeviceManagement {
  action?: string;
}
const Setup: FC<DeviceManagement> = (props): JSX.Element => {
  const { state, dispatch } = useContext(AppContext);

  const { version } = useAppVersion();

  const navigate = useNavigate();

  const {
    retrieveDevices,
    searchDevices,
    searchText,
    handlePageChange,
    handleRowsPerPageChange,
    handleSorting,
  } = useGlobalState();

  useLayoutEffect(() => {
    dispatch({ type: ActionType.SET_DEVICES, payload: [] });
    dispatch({ type: ActionType.SET_TOTAL_RECORDS, payload: undefined });

    if (searchText.length > 0) {
      searchDevices("");
    } else {
      retrieveDevices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToAddEditDevice = (id: string) => {
    id
      ? navigate(`/${version}/devices/${encodeURIComponent(id)}/edit`)
      : navigate(`/${version}/devices/add`);
  };

  return (
    <Page>
      <DeviceTableComp
        {...props}
        rows={state.devices}
        totalRows={state.totalRows}
        searchCallback={searchDevices}
        addEditDevicecallback={navigateToAddEditDevice}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        handleSorting={handleSorting}
      />
    </Page>
  );
};

export default Setup;
