import { FC } from "react";
import { Navigate, Route, Routes, BrowserRouter } from "react-router-dom";
import Manager from "./pages/DeviceManagement";
import UserManagement from "./pages/UserManagement";
import DeviceHistory from "./pages/DeviceHistory";
import Login from "./pages/Login";
import PageNotFound from "./pages/PageNotFound";
import Header from "./components/layout/header";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import AddEditDevice from "./pages/AddEditDevice";
import AddEditUser from "./pages/AddEditUser";
import Authenticated from "./components/authenticated";
import { GlobalStateProvider } from "./lib/GlobalState";
import DeviceAlerts from "./pages/DeviceAlerts";
import { useApiVersion } from "./lib/helper";

const DefaultRedirectPath = () => {
  const version = useApiVersion();
  return <Navigate replace to={`/${version}/devices`} />;
};
const ManagerRedirectPath = (param) => {
  return <Navigate replace to={`/${param.version}/devices`} />;
};
const UserManagerRedirectPath = (param) => {
  return <Navigate replace to={`/${param.version}/users`} />;
};

const App: FC = () => {
  return (
    <BrowserRouter>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        draggable={false}
        limit={2}
        role="alert"
        transition={Flip}
      />
      <GlobalStateProvider>
        <Header />
        <Routes>
          <Route path="/" element={<DefaultRedirectPath />} />
          <Route path="/login" element={<Login />} />
          <Route path="/v1/manager" element={<ManagerRedirectPath version="v1" />} />
          <Route path="/v2/manager" element={<ManagerRedirectPath version="v2" />} />
          <Route path="/v1/user-management" element={<UserManagerRedirectPath version="v1" />} />
          <Route path="/v2/user-management" element={<UserManagerRedirectPath version="v2" />} />
          <Route path="/v1/devices">
            <Route
              path=""
              element={
                <Authenticated>
                  <Manager />
                </Authenticated>
              }
            />
            <Route
              path="add"
              element={
                <Authenticated>
                  <AddEditDevice />
                </Authenticated>
              }
            />
            <Route
              path=":deviceID"
              element={
                <Authenticated>
                  <Manager />
                </Authenticated>
              }
            />
            <Route
              path=":deviceID/edit"
              element={
                <Authenticated>
                  <AddEditDevice />
                </Authenticated>
              }
            />
            <Route
              path=":deviceID/otp"
              element={
                <Authenticated>
                  <Manager />
                </Authenticated>
              }
            />
            <Route
              path="export"
              element={
                <Authenticated>
                  <Manager />
                </Authenticated>
              }
            />
          </Route>
          <Route path="/v1/users">
            <Route
              path=""
              element={
                <Authenticated>
                  <UserManagement />
                </Authenticated>
              }
            />
            <Route
              path="add"
              element={
                <Authenticated>
                  <AddEditUser />
                </Authenticated>
              }
            />
            <Route
              path=":username/edit"
              element={
                <Authenticated>
                  <AddEditUser />
                </Authenticated>
              }
            />
            <Route
              path="export"
              element={
                <Authenticated>
                  <UserManagement />
                </Authenticated>
              }
            />
          </Route>
          <Route
            path="/v2/device-history"
            element={
              <Authenticated>
                <DeviceHistory />
              </Authenticated>
            }
          />
          <Route
            path="/v2/alerts"
            element={
              <Authenticated>
                <DeviceAlerts />
              </Authenticated>
            }
          />
          <Route path="/v2/devices">
            <Route
              path=""
              element={
                <Authenticated>
                  <Manager />
                </Authenticated>
              }
            />
            <Route
              path="add"
              element={
                <Authenticated>
                  <AddEditDevice />
                </Authenticated>
              }
            />
            <Route
              path=":deviceID"
              element={
                <Authenticated>
                  <Manager />
                </Authenticated>
              }
            />
            <Route
              path=":deviceID/edit"
              element={
                <Authenticated>
                  <AddEditDevice />
                </Authenticated>
              }
            />
            <Route
              path=":deviceID/otp"
              element={
                <Authenticated>
                  <Manager />
                </Authenticated>
              }
            />
            <Route
              path="export"
              element={
                <Authenticated>
                  <Manager />
                </Authenticated>
              }
            />
          </Route>
          <Route path="/v2/users">
            <Route
              path=""
              element={
                <Authenticated>
                  <UserManagement />
                </Authenticated>
              }
            />
            <Route
              path="add"
              element={
                <Authenticated>
                  <AddEditUser />
                </Authenticated>
              }
            />
            <Route
              path=":id/edit"
              element={
                <Authenticated>
                  <AddEditUser />
                </Authenticated>
              }
            />
            <Route
              path="export"
              element={
                <Authenticated>
                  <UserManagement />
                </Authenticated>
              }
            />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </GlobalStateProvider>
    </BrowserRouter>
  );
};

export default App;
