import { FC } from "react";
import { ERROR_MESSGAES } from "../constants";
export interface DeviceFieldErrorProps {
  message1: ERROR_MESSGAES;
  message2?: ERROR_MESSGAES;
  validationErrors: string[];
}

const DeviceFieldErrorComponent: FC<DeviceFieldErrorProps> = ({
  message1,
  message2,
  validationErrors,
}): JSX.Element => {
  return message1 && message2 ? (
    <>
      {(validationErrors.includes(message2) || validationErrors.includes(message1)) && (
        <div className="inline-flex mt-1">
          <div className="ml-1 text-red-500">{validationErrors.includes(message2) ? message2 : message1}</div>
        </div>
      )}
    </>
  ) : message1 ? (
    <>
      {validationErrors.includes(message1) && (
        <div className="inline-flex mt-1">
          <div className="ml-1 text-red-500">{message1}</div>
        </div>
      )}
    </>
  ) : null;
};

export default DeviceFieldErrorComponent;
