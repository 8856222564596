import { createContext, useReducer, Dispatch, useContext } from "react";
import { reducer, Action } from "./reducer";

//  All state for the application
export interface StateContext {
  userRole: string;
  devices: [];
  exportDevices: [];
  users: [];
  deviceHistory: [];
  deviceAlerts: [];
  totalRows: number;
  nextPage: [
    {
      id: number;
      page: string;
    },
  ];
  page: {
    limit: number;
    page: number;
    totalRows: number;
    searchText: string | undefined;
  };
}

export interface Store {
  state: any;
  dispatch?: Dispatch<Action>;
}

// The default state for the application
const initialState = {
  currentDevice: {},
  currentUser: {},
  userRole: null,
  devices: [],
  exportDevices: [],
  users: [],
  deviceHistory: [],
  deviceAlerts: [],
  nextPage: [{ page: "" }],
  totalRows: 30,
  page: {},
};

// Create context
export const AppContext = createContext<Store>({ state: initialState });

//Export context as a function so it can be used by importing useStateContext()
export const useStateContext = () => useContext(AppContext);

// The Provider component to provide the global state to all child components
const StateProvider = ({ children }) => {
  //@ts-ignore
  const [state, dispatch] = useReducer(reducer, initialState);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export default StateProvider;
