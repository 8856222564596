import { FC } from "react";
import DeviceAddModal from "../components/DeviceAddModal";
import V2DeviceAddModal from "../components/v2/V2DeviceAddEditModal";
import Page from "../components/layout/Page";
import { useParams } from "react-router-dom";
import { useAppVersion } from "../lib/hooks/useAppVersion";

const AddDevice: FC = (props) => {
  const { version } = useAppVersion();
  const params = useParams();
  const modalHeader = params.deviceID ? "Edit Device" : "Add Device";

  return (
    <Page>
      <div className="max-w-7xl mx-auto py-8 px-5 bg-white">
        <div className="flex px-4 py-3 sm:px-6 sm:flex">
          <h1 className="flex-grow ml-3 text-xl font-bold leading-9 text-gray-900">{modalHeader}</h1>
        </div>
        {version === "v2" ? (
          <V2DeviceAddModal pageType={modalHeader === "Edit Device" ? "EDIT" : "ADD"} />
        ) : (
          <DeviceAddModal pageType={modalHeader === "Edit Device" ? "EDIT" : "ADD"} />
        )}
      </div>
    </Page>
  );
};

export default AddDevice;
