import { Location, useLocation } from "react-router-dom";
import { ApiVersion } from "../../constants";
import { useApiVersion } from "../helper";

type AppVersion = {
  version: ApiVersion;
};
const getVersionFromLocationPath = (location: Location) => {
  const pathname = location.pathname.split("/");
  return pathname[1].startsWith("v") ? pathname[1] : undefined;
};

export const useAppVersion = (): AppVersion => {
  const location = useLocation();
  const version = useApiVersion();
  return { version: (getVersionFromLocationPath(location) || version) as ApiVersion };
};
