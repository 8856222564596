import { FC } from "react";
export interface DeviceFieldProps {
  id: string;
  value: string;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  title?: string;
  onChange?: (event: any) => void;
}

const DeviceFieldComponent: FC<DeviceFieldProps> = ({
  id,
  value,
  className,
  onChange,
  required,
  disabled,
  title,
}): JSX.Element => {
  return title ? (
    <div className="edit-data-row">
      <dt className="text-sm font-medium text-gray-700">{title}</dt>
      <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span className="flex-grow">
          <input
            disabled={disabled}
            value={value}
            onChange={onChange}
            data-testid={`testId-${id}`}
            type="text"
            name={id}
            id={id}
            key={id}
            required={required}
            className={className}
          />
        </span>
      </dd>
    </div>
  ) : (
    <input
      disabled={disabled}
      value={value}
      onChange={onChange}
      data-testid={`testId-${id}`}
      type="text"
      name={id}
      id={id}
      key={id}
      required={required}
      className={className}
      autoFocus
    />
  );
};

export default DeviceFieldComponent;
