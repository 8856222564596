import { useState, useLayoutEffect, useEffect, useRef, useCallback } from "react";
import { useApi } from "../useApi";
import { getUserManagementApiClient as apiClient } from "../../api-helper/apiHelper";
import {
  USER_ID,
  USER_EXISTS,
  INVALID_DOMAIN,
  USER_NOT_EXIST,
  ModelConfirmation,
  userMapper,
} from "../../../constants";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppVersion } from "../useAppVersion";
import { UpdateUserParams2DeactivationCodeEnum } from "../../../openapi/device-api/api";
import { ModalData } from "../../../components/types/DeviceModal";
import { UserInfo, V2UserDataProps } from "../../models";
import { displayMessage } from "../renderHelpers";
import { validFields, convertObjCamelCaseToUnderscore, mappedEntries } from "../helpers";
import { getCorrelationId } from "../helpers";

const initData = {
  id: "",
  email: "",
  username: "",
  enable: false,
  enabled: false,
  deactivationCode: "",
  role: "",
};

export const V2UseAddEditUser = () => {
  const [userInfo, setUserInfo] = useState<UserInfo>(initData);
  const [isDirtyState, setIsDirtyState] = useState<boolean>(false);
  const [chooseDeactivationCode, setChooseDeactivationCode] = useState<boolean>(false);
  const [orgUserState, setOrgUserState] = useState<string>("");
  const [error, setError] = useState<string>();
  const [modalStatus, setModalStatus] = useState<string>("ADD");
  const [isShowCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [isShowStateModal, setShowStateWarning] = useState<boolean>(false);
  const toggleCancelWarning = () => setShowCancelModal(!isShowCancelModal);
  const toggleStateWarning = () => setShowStateWarning(!isShowStateModal);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const correlationId = useRef<string>(getCorrelationId());
  const headers = { headers: { "X-Correlation-ID": correlationId.current } };
  const editDataFetched = useRef<boolean>(false);
  const [searchParams] = useSearchParams();
  const userId = searchParams.get(USER_ID);
  const { version } = useAppVersion();
  const location = window.location;
  const navigate = useNavigate();
  const params = useParams();

  const onUserDataChange = (field: string, selected: any) => {
    editDataFetched.current = true;
    setIsDirty(true);
    setUserInfo({ ...userInfo, [field]: selected });
    if (field === "enabled") {
      if (!selected) {
        setUserInfo({
          ...userInfo,
          enabled: false,
          deactivationCode: UpdateUserParams2DeactivationCodeEnum.NoLongerWithPostoffice,
        });
      }
      setChooseDeactivationCode(false);
      setIsDirtyState(false);
      if (userData.enable !== selected) {
        setIsDirtyState(true);
      }
    }
  };

  const confirmCancel = (confirmation: string) => {
    if (isDirty) toggleCancelWarning();
    if (confirmation === ModelConfirmation.Yes) {
      setIsDirty(false);
      navigate(-1);
    }
  };

  const confirmStateChanges = (confirmation: string) => {
    toggleStateWarning();
    if (confirmation === ModelConfirmation.Yes) {
      setShowStateWarning(false);
      handleEditUser();
    } else {
      toggleStateWarning();
    }
  };

  const handleBackButtonEvent = (event: PopStateEvent) => {
    event.preventDefault();
    if (isDirty) {
      toggleCancelWarning();
    } else {
      navigate(`/${version}/users`);
    }
  };

  const handleAddUser = useCallback(async () => {
    if (isDirty || isDirtyState) {
      const errors = validFields(userInfo as unknown as Record<string, unknown>, [
        orgUserState,
        chooseDeactivationCode,
      ]);
      if (errors?.length === 0) {
        addUserResponse || addUserError ? refresh() : addUser(`${version}PostUser`);
        await addUserResponse;
      } else {
        displayMessage(errors);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, isDirty, isDirtyState, chooseDeactivationCode, orgUserState, version]);

  const handleUpdateButton = () => {
    if (isDirtyState) {
      toggleStateWarning();
    } else {
      handleEditUser();
    }
  };

  const handleEditUser = async () => {
    const errors = validFields(userInfo as unknown as Record<string, unknown>);
    if (errors?.length === 0) {
      updateUserResponse || updateUserError ? updateRefresh() : updateUser(`${version}PutUser`);
      await updateUserResponse;
    } else {
      displayMessage(errors);
    }
  };

  const handleCancelButton = () => {
    if (isDirty) {
      toggleCancelWarning();
      navigate(-1);
    } else {
      navigate(-1);
    }
  };

  const processResponse = () => {
    if (userDataStatus === 200) {
      setUserInfo({
        ...userInfo,
        ...mappedEntries(userData, userMapper),
      });
      setOrgUserState(userData.enabled === false ? userData.enabled : true);
    } else if (userDataStatus === 404) {
      displayMessage([USER_NOT_EXIST]);
    } else {
      displayMessage([userDataError]);
    }
  };

  const putApiOptions = {
    id: userId,
    updateUser: {
      ...convertObjCamelCaseToUnderscore(userInfo as unknown as Record<string, unknown>),
      enable: userInfo.enabled,
    },
    headers,
  };

  // add user
  const [{ data: addUserResponse, status: addUserStatus, error: addUserError, statusCode }, addUser, refresh] = useApi(
    apiClient,
    [
      {
        ...convertObjCamelCaseToUnderscore(userInfo as unknown as Record<string, unknown>),
        username: userInfo.email,
        enable: true,
        deactivation_code: UpdateUserParams2DeactivationCodeEnum.NoLongerWithPostoffice,
      },
      headers,
    ],
  );

  // update user
  const [
    { data: updateUserResponse, status: updateUserStatus, error: updateUserError, statusCode: updateUserStatusCode },
    updateUser,
    updateRefresh,
  ] = useApi(apiClient, putApiOptions, "", "PUT");

  // get user
  const [{ data: userData, error: userDataError, statusCode: userDataStatus }, getUserData] = useApi(
    apiClient,
    [params.id, headers],
    undefined,
    "GET",
  );

  useLayoutEffect(() => {
    processResponse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, userDataStatus, userDataError]);

  // identify if edit or add modal
  useLayoutEffect((): void => {
    params.id ? setModalStatus("EDIT") : setModalStatus("ADD");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useLayoutEffect(() => {
    if (addUserResponse !== typeof undefined && (statusCode === 200 || statusCode === 201)) {
      setError(null);
      setIsDirty(false);
      displayMessage(["User Added"], "info");
      navigate(`/${version}/users`, { replace: true });
    }

    if (addUserResponse !== typeof undefined && addUserStatus === "error") {
      if (addUserError.includes("UsernameExistsException")) {
        setError(USER_EXISTS);
      } else if (addUserError.includes("UserLambdaValidationException")) {
        setError(INVALID_DOMAIN);
      } else {
        setError(addUserError);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUserResponse, statusCode, addUserStatus, addUserError]);

  useLayoutEffect(() => {
    if (updateUserResponse !== typeof undefined && (updateUserStatusCode === 200 || updateUserStatusCode === 204)) {
      displayMessage(["User Updated"], "info");
      setIsDirty(false);
      setTimeout(() => {
        navigate(`/${version}/users`, { replace: true });
      }, 1000);
    }

    if (updateUserResponse !== typeof undefined && updateUserStatusCode === 400) {
      displayMessage([USER_NOT_EXIST]);
    }

    if (updateUserResponse !== typeof undefined && updateUserStatus === "error") {
      displayMessage([updateUserError]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserResponse, updateUserStatusCode, updateUserStatus, updateUserError]);

  useEffect(() => {
    if (params.id) {
      getUserData(`${version}GetUser`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!isShowCancelModal && isDirty) {
      window.history.pushState(null, null, window.location.pathname + `#/user?${USER_ID}=${userId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowCancelModal]);

  useEffect(() => {
    if (isDirty) {
      window.history.pushState(null, null, window.location.pathname + `#/user?${USER_ID}=${userId}`);
      window.addEventListener("popstate", handleBackButtonEvent);
      return () => {
        window.removeEventListener("popstate", handleBackButtonEvent);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    if (location.hash.includes("#/user")) {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modalData: ModalData = {
    isShowCancelModal,
    isShowStateModal,
    toggleCancelWarning,
    confirmCancel,
    toggleStateWarning,
    confirmStateChanges,
  };

  const userDetails: V2UserDataProps = {
    ...userInfo,
    modalStatus,
    enable: modalStatus === "ADD" ? true : userInfo.enabled === false ? userInfo.enabled : true,
    deactivationCode:
      userInfo.enabled === false ? userInfo.deactivationCode || updateUserResponse?.deactivation_code : "",
  };

  return {
    handleAddUser,
    handleUpdateButton,
    handleCancelButton,
    onUserDataChange,
    userDetails,
    modalData,
    error,
  };
};
