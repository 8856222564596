import {
  Device2StateEnum,
  Device2DeactivationCodeEnum,
  UpdateUserParams2DeactivationCodeEnum,
  CreateUserParamsRoleEnum,
  DeviceTypeEnum,
} from "../openapi/device-api/api";

export const ADMIN = CreateUserParamsRoleEnum.Admin;
export const ADMIN_LABEL = "Admin";
export const USER = CreateUserParamsRoleEnum.User;
export const USER_LABEL = "User";
export const Add_USER = "Add User";
export const EDIT_USER = "Edit User";
export const CREATE_USER = "Create User";
export const UPDATE_USER = "Update User";
export const DEVICE_REGISTERED_SUCCESSFULLY = "Device Added - Click Generate OTP to get your OTP";
export const DEVICE_UPDATED_SUCCESSFULLY = "Device Updated";
export const USER_ID = "id";
export const DEFAULT_DATE = "0001-01-01T00:00:00Z";

export const USER_EXISTS = "User already exists";
export const USER_NOT_EXIST = "User does not exist";
export const INVALID_DOMAIN = "Domain not associated to PostOffice. Please enter valid domain.";

export const INPUT_BOX_MAX_CHARACTERS = 50;
export const INPUT_BOX_MIN_CHARACTERS = 3;
export const DEVICE_ID_MAX_CHARACTERS = 20;
export const DEVICE_NAME_MAX_CHARACTERS = 40;
export const NOTES_MAX_CHARACTERS = 256;
export const ROWS_PER_PAGE_V1 = 60;
export const ROWS_PER_PAGE_V2 = 10;

export const BRANCH_ID_DIGIT_REGEXP = new RegExp(/^\d{6}$/i);
export const BRANCH_ID_REGEXP = new RegExp(/^\d{6}[\dX]?$/);

export const NO_RESULT_FOUND = "No results found.";

export enum ERROR_MESSGAES {
  DEVICE_ID_MAX_CHAR_ERROR = "Max. Char limit for Device ID is 20.",
  DEVICE_ID_MIN_CHAR_ERROR = "Device ID is a mandatory field. Please enter at least 3 character.",
  DEVICE_ID_SPACE_CHAR_ERROR = "Device ID should not contain any spaces",
  DEVICE_ID_ALREADY_EXISTS_ERROR = "Device ID exists. Please try with new Device ID",
  DEVICE_NAME_MAX_CHAR_ERROR = "Max Char limit for Device Name is 40.",
  DEVICE_NAME_MIN_CHAR_ERROR = "Device Name is a mandatory field. Please enter at least 3 characters.",
  BRANCH_ID_ERROR = "Branch ID is a mandatory field. Please enter 6 to 7 digits or 6 digits and X.",
  BRANCH_ID_NOT_FOUND_ERROR = "No Active branch/matching branch code. Branch ID can be 6 to 7 digits or 6 digits and X",
  BRANCH_ID_CHANGE_ERROR = "You have made changes to Branch ID. Please validate it by clicking Find Branch",
  DEVICE_TYPE_ERROR = "Please select the Device type.",
  DEVICE_DEACTIVATION_CODE_ERROR = "Reason code is mandatory while deactivating a device",
  NODE_ID_MAX_CHAR_ERROR = "Max Char limit for Node Id is 2.",
  NODE_ID_ERROR = "Node Id is a mandatory field. Please choose a node between 32 and 63.",
  NODE_ID_ALREADY_USED_ERROR = "Node ID entered is allocated to another device in the current branch. Please try another Node ID",
  NOTES_MAX_CHAR_ERROR = "Max Char limit for Notes is 256.",
}
export const DEVICE_TYPE_OPTIONS = [
  { value: DeviceTypeEnum.Payzone, label: DeviceTypeEnum.Payzone },
  { value: DeviceTypeEnum.Counter, label: DeviceTypeEnum.Counter },
  { value: DeviceTypeEnum.Mobile, label: DeviceTypeEnum.Mobile },
  { value: DeviceTypeEnum.Mobiledrs, label: DeviceTypeEnum.Mobiledrs },
];

export const DEVICE_STATE_OPTIONS = [
  { label: "Activate", value: Device2StateEnum.Active },
  { label: "Deactivate", value: Device2StateEnum.Inactive },
];
export const DEVICE_DEACTIVATION_CODE = [
  { label: "Faulty device", value: Device2DeactivationCodeEnum.FaultyDevice },
  { label: "Branch closed", value: Device2DeactivationCodeEnum.BranchClosed },
  {
    label: "Device no longer in use",
    value: Device2DeactivationCodeEnum.DeviceNoLongerInUse,
  },
  {
    label: "Other",
    value: Device2DeactivationCodeEnum.Other,
  },
];
export enum BRANCH_FINDER_ERRORS {
  ERROR_901 = "Failed to retrieve branch details",
  ERROR_900 = "Failed to retrieve branch details",
}

export const roleOptions = [
  { value: ADMIN, label: ADMIN_LABEL },
  { value: USER, label: USER_LABEL },
];
export const userStateOptions = [
  { value: true, label: "Enable" },
  { value: false, label: "Disable" },
];
export const userDeactivationCodeOptions = [
  {
    value: UpdateUserParams2DeactivationCodeEnum.NoLongerWithPostoffice,
    label: "User no longer with Postoffice",
  },
  {
    value: UpdateUserParams2DeactivationCodeEnum.NotReceivedActivationLink,
    label: "User has not received activation link",
  },
  {
    value: UpdateUserParams2DeactivationCodeEnum.NotYetVerified,
    label: "User not yet verified",
  },
];

export enum ModelConfirmation {
  Yes = "YES",
  No = "NO",
}

export type ApiVersion = "v1" | "v2";

export const deviceMapper = {
  branchId: "branch_id",
  id: "id",
  name: "name",
  nodeId: "node_id",
  type: "type",
  state: "state",
  deactivationCode: "deactivation_code",
  notes: "notes",
};

export const branchMapper = {
  branchName: "branch_name",
  branchAddress: "branch_address",
  branchOrgUnitCodeVersion: "branch_org_unit_code_version",
  branchOrgUnitCode: "branch_org_unit_code",
  branchPostcode: "branch_postcode",
  locationTypeCode: "location_type_code",
  locationTypeName: "location_type_name",
  tradingOpenStatus: "trading_open_status",
  tradingSubStatusCode: "trading_sub_status_code",
  tradingSubStatusName: "trading_sub_status_name",
};

export const userMapper = {
  id: "id",
  email: "email",
  role: "role",
  enabled: "enabled",
  deactivationCode: "deactivation_code",
};
