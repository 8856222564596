import { useContext, useEffect, useState } from "react";
import { useApi } from "./useApi";
import { AppContext } from "../../context/store";
import { ActionType } from "../../context/reducer";
import { getDeviceAlertsApiClient as deviceApiClient } from "../api-helper/apiHelper";
import { ROWS_PER_PAGE_V2 } from "../../constants";
import { useSearchParams } from "react-router-dom";

export const useDeviceAlerts = () => {
  const { dispatch } = useContext(AppContext);
  const [searchText, setSearchText] = useState<string>();
  const [totalRows, setTotalRows] = useState<number>();
  const [limit, setLimit] = useState<number>(ROWS_PER_PAGE_V2);
  const [page, setPage] = useState<number>(1);
  let [searchParams, setSearchParams] = useSearchParams();

  const [{ data: alertsData }, getAlerts, alertsRefresh] = useApi(
    deviceApiClient,
    [limit, page, undefined, undefined, searchText],
    "",
    "GET",
  );

  useEffect(() => {
    if (searchParams) {
      if (searchParams.get("limit")) {
        setLimit(Number(searchParams.get("limit")));
        handleRowsPerPageChange(Number(searchParams.get("limit")), page);
      }
      if (searchParams.get("page")) {
        handlePageChange(Number(searchParams.get("page")));
      }
      if (searchParams.get("q")) {
        setSearchText(String(searchParams.get("q")));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function loadAlertsData() {
      setTotalRows(alertsData.pagination.total);
      dispatch({ type: ActionType.SET_DEVICE_ALERTS, payload: alertsData.items });
    }

    alertsData && loadAlertsData();
  }, [dispatch, alertsData]);

  useEffect(() => {
    alertsRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const retrieveDeviceAlerts = () => {
    getAlerts(`v2GetAlerts`);
  };

  const searchAlerts = (searchString: string) => {
    setSearchText(searchString);
    if (searchString) {
      searchParams.set("q", searchString);
      setSearchParams(searchParams);
    } else {
      setSearchText(undefined);
    }
  };

  const handlePageChange = (page) => {
    searchParams.set("page", page);
    setSearchParams(searchParams);
    setPage(page);
    getAlerts(`v2GetAlerts`);
    alertsRefresh();
  };

  const handleRowsPerPageChange = (newPerPage, page) => {
    searchParams.set("limit", newPerPage);
    searchParams.set("page", page);
    setSearchParams(searchParams);
    setLimit(newPerPage);
    setPage(page);
    getAlerts(`v2GetAlerts`);
    alertsRefresh();
  };

  return {
    retrieveDeviceAlerts,
    searchAlerts,
    totalRows,
    handlePageChange,
    handleRowsPerPageChange,
  };
};

export default useDeviceAlerts;
