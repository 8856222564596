import { StateContext } from "./store";
import { Device, Devices as IDevice, Users, User, DeviceAlerts, Page } from "../lib/models";

// Reducer action type to prevent string literals being passed around
export enum ActionType {
  SET_DEVICES = "SET_DEVICES",
  SET_USERS = "SET_USERS",
  NEXT_PAGE = "NEXT_PAGE",
  SET_TOTAL_RECORDS = "SET_TOTAL_RECORDS",
  SET_DEVICE = "SET_DEVICE",
  SET_USER = "SET_USER",
  SET_USER_ROLE = "SET_USER_ROLE",
  SET_DEVICE_ALERTS = "SET_DEVICE_ALERTS",
  SET_PAGE = "SET_PAGE",
}

// Setting Models to actions
export type Action =
  | { type: ActionType.SET_DEVICES; payload: IDevice }
  | { type: ActionType.SET_USERS; payload: Users }
  | { type: ActionType.NEXT_PAGE; payload: "" }
  | { type: ActionType.SET_TOTAL_RECORDS; payload: number }
  | { type: ActionType.SET_DEVICE; payload: Device }
  | { type: ActionType.SET_USER; payload: User }
  | { type: ActionType.SET_USER_ROLE; payload: string }
  | { type: ActionType.SET_DEVICE_ALERTS; payload: DeviceAlerts }
  | { type: ActionType.SET_PAGE; payload: Page };

// Reducer to set application state
export const reducer = (state: StateContext, action: Action) => {
  switch (action.type) {
    case ActionType.SET_DEVICES:
      return { ...state, devices: action.payload };
    case ActionType.SET_USERS:
      return { ...state, users: action.payload };
    case ActionType.SET_TOTAL_RECORDS:
      return { ...state, totalRows: action.payload };
    case ActionType.NEXT_PAGE:
      return {
        ...state,
        nextPage: [
          ...state.nextPage,
          {
            page: action.payload,
          },
        ],
      };
    case ActionType.SET_DEVICE:
      return { ...state, currentDevice: action.payload };
    case ActionType.SET_DEVICE_ALERTS:
      return { ...state, deviceAlerts: action.payload };
    case ActionType.SET_USER:
      return { ...state, currentUser: action.payload };
    case ActionType.SET_USER_ROLE:
      return { ...state, userRole: action.payload };
    case ActionType.SET_PAGE:
      return { ...state, page: action.payload };
    default:
      throw new Error("Not among actions");
  }
};
