import { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import NoResultComponent from "./NoResultComp";
import { useSearchParams } from "react-router-dom";
import { SearchBox } from "./table/searchBox";
import { PaginableTable } from "../lib/hooks/useDeviceHistory";
import { loadingSpinner } from "./loadingSpinner";

export interface DeviceHistoryTableProps<DataType, ColumnType> {
  history: PaginableTable<DataType, ColumnType>;
}

const DeviceHistoryTableComp = <DataType, ColumnType>(props: DeviceHistoryTableProps<DataType, ColumnType>) => {
  const { history } = props;
  let [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState<string>(searchParams.get("q") || "");

  const handleKeyUp = (e: { key: string }) => {
    if ((e.key === "Enter" && searchText !== "" && searchText.length > 2) || searchText.length === 0) {
      history.onSearch(searchText);
    }
  };

  const onReset = () => {
    searchParams.delete("q");
    setSearchParams(searchParams);
    setSearchText("");
    history.onSearch("");
  };

  const subHeaderComponentMemo = useMemo((): JSX.Element => {
    return (
      <div className="inline-flex items-end w-1/4 py-4">
        <SearchBox
          value={searchText}
          onKeyUp={handleKeyUp}
          onReset={onReset}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <div data-testid="dataTable">
      <DataTable
        className="data-table"
        paginationDefaultPage={history.page}
        columns={history.columns}
        data={history.data}
        pagination
        highlightOnHover
        noDataComponent={<NoResultComponent message="There are no records to display" />}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        defaultSortAsc
        onSort={history.onSort}
        sortServer={true}
        paginationServer
        paginationTotalRows={history.totalRows}
        paginationPerPage={history.perPage}
        onChangeRowsPerPage={history.onChangeRowPerPage}
        onChangePage={history.onChangePage}
        {...(history.totalRows === undefined
          ? {
              progressPending: true,
              progressComponent: loadingSpinner("Loading devices history..."),
            }
          : "")}
      />
    </div>
  );
};

export default DeviceHistoryTableComp;
